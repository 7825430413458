export const Users = "users";
export const Login = "user/login";
export const Signup = "user/signup";
export const BlockChain = "blockchain";
export const CreatePaylink = "create_paylinks";
export const MerchantProduct = "merchant_product";
export const MerchantSetting = "merchant_settings";
export const MerchantAddress = "merchant_address";
export const MerchantOrders = "merchant_orders";
export const GenerateKey = "generate_key";
export const MerchantOrderProduct = "merchant_orders_products";
export const CreateSingleCategory = "create_single_categories";
export const GetCategory = "get_categories";
export const DeleteCategory = "delete_category";
export const MerchantAddressDelete = "merchant_address_delete";
export const DeletePaylink = "delete_paylinks";
export const MerchantAddressEnDis = "merchant_address_en_dis";
export const Ecommerce = "ecommerce";
export const MerchantEcommerce = "merchant-ecommerce";
export const MerchantOrdersDetails = "merchant_orders_details";
