import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import CheckoutComponent from "../CheckoutComponent";
import EcommerceComponent from "../EcommerceComponent";
import ConfigurationComponent from "../ConfigurationComponent";
import GenerateKeyComponent from "../GenerateKeyComponent";
import { getStorageData } from "utils/helper";

function SettingComponent() {
  const history = useHistory();

  useEffect(() => {
    const getWalletEmail = getStorageData("infra:email");
    if (getWalletEmail == null) {
      return history.push("/login");
    }
  }, []);

  return (
    <div className="settingWrapper padd30 bgLight radius10">
      <div className="settingHeader">
        <p className="mainHeading">Settings</p>
        <span className="mainHeadingPara">Customize your checkout page</span>
      </div>
      <div className="settingBody">
        <div className="checkoutSettingsContainer">
          <p className="subHeading">Checkout Settings</p>
          <CheckoutComponent />
        </div>
        <div className="checkoutSettingsContainer">
          <p className="subHeading">Ecommerce Settings</p>
          <EcommerceComponent />
        </div>
        <div className="configSettingsContainer">
          <p className="subHeading">Blockchain/Wallet Address Configuration</p>
          <ConfigurationComponent />
        </div>
        <div className="generateKeyContainer border0">
          <p className="subHeading">Generate API Key</p>
          <GenerateKeyComponent />
        </div>
      </div>
    </div>
  );
}

export default SettingComponent;
