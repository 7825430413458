import DashboardComponent from 'components/Dashboard/DashboardComponent'
import React from 'react'
import './styles.css'

const DashboardContainer = (props: any) => {
  return (
    <DashboardComponent/>
  )
}

export default DashboardContainer