import React from "react";
import propTypes from "prop-types";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Layout from "./DefaultLayout";
import DashboardPage from "../pages/Dashboard";
import PaymentsPage from "../pages/Paylinks";
import NotFound from "../components/NotFound";
import POSPage from "pages/POS";
import SettingPage from "pages/Setting";
import { getStorageData } from "utils/helper";

const CRoutes = (props: any) => {

  const RouteRedirect = () => {
    const walletEmail = localStorage.getItem("infra:email");
    if (walletEmail !== null && walletEmail !== undefined) {
      let pathname = "/dashboard";
      return pathname;
    } else {
      let pathname = "/login";
      return pathname;
    }
  };

  return (
    <Switch>
      <Redirect exact from="/" to={RouteRedirect()} />
      <Route
        path="/dashboard"
        component={(props: any) => (
          <Layout {...props} Component={DashboardPage} />
        )}
      />
      <Route
        path="/pos"
        component={(props: any) => <Layout {...props} Component={POSPage} />}
      />
      <Route
        path="/paylinks"
        component={(props: any) => (
          <Layout {...props} Component={PaymentsPage} />
        )}
      />
      <Route
        path="/settings"
        component={(props: any) => (
          <Layout {...props} Component={SettingPage} />
        )}
      />
      <Route
        path="/*"
        component={(props: any) => <Layout {...props} Component={NotFound} />}
      />
    </Switch>
  );
};

CRoutes.propTypes = {
  location: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
};

export default CRoutes;
