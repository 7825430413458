import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { Button, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getPaylinkAPI } from "apiServices/paylinks";

interface DataType {
  key: React.Key;
  client_address: string;
  payment_id: string;
  amount: string;
  status: string;
  order_type: string;
  date_time: string;
}

const CustomerListComponent = ({ setCustomerOrderList }: any) => {
  const [orderList, setOrderList] = useState<any[]>([]);
  const [ordersText, setOrdersText] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer ID",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Customer Address",
      dataIndex: "client_address",
      key: "client_address",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      dataIndex: "operation",
      width: 100,
    },
  ];
  const history = useHistory();

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          client_address: item.client_address,
          operation: (
            <Button
              onClick={() => {
                setCustomerOrderList(item);
              }}
            >
              View History
            </Button>
          ),
        };
      })
    );
  };

  const fetchOrders = (walletEmail: any) => {
    setShowLoader(true);
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setShowLoader(false);
            setOrdersText("OOPS you dont have any record");
          } else {
            const isOrdersCompleted = responseData.filter(
              (order: any) => order.status !== "pending"
            );
            const isSuiOrders = isOrdersCompleted.filter(
              (order: any) => order.coin === "SUI"
            );
            const ordersData = isSuiOrders.filter(
              (item: any) =>
                item.order_type === "POS" || item.order_type === "Ecommerce"
            );
            const groupedByClientAddress: { [key: string]: Array<any> } = {};
            ordersData.forEach((order: any) => {
              const { client_address } = order;

              if (groupedByClientAddress[client_address]) {
                groupedByClientAddress[client_address].push(order);
              } else {
                groupedByClientAddress[client_address] = [order];
              }
            });
            const formattedResult = Object.entries(groupedByClientAddress).map(
              ([client_address, orders], index) => ({
                sequence: index + 1,
                client_address,
                orders,
              })
            );
            if (formattedResult.length > 0) {
              setOrdersText("");
              setShowLoader(false);
              setOrderList(formattedResult);
            } else {
              setShowLoader(false);
              setOrdersText("OOPS you dont have any record");
            }
          }
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const walletEmail = localStorage.getItem("infra:email");
    if (walletEmail == null || walletEmail == undefined) {
      return history.push("/login");
    } else {
      fetchOrders(walletEmail);
    }
  }, []);

  return (
    <div>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Table dataSource={responseFormate(orderList)} columns={columns} />
      )}
    </div>
  );
};

export default CustomerListComponent;
