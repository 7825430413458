import React, { useEffect, useState } from "react";
import { SettingIcon } from "svgIcon";
import { LogoutIcon } from "svgIcon";
import { useHistory } from "react-router-dom";
import { getStorageData, toastMessage } from "utils/helper";
import { getUsers } from "apiServices/app";
import { Image, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MonogramIcon from "assets/images/mongram.jpg";
import { baseImageURL } from "utils/env";

const ProfileModal = ({ setShowUserProfile, merchanrContent }: any) => {
  const [profileContent, setProfileContent] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();

  const manageUsersData = (getWalletEmail: any) => {
    setShowLoader(true);
    try {
      getUsers().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          const filteredData = responseData.filter((item: any) => {
            return item.email === getWalletEmail;
          });
          if (filteredData.length === 0) {
            setShowLoader(false);
            console.log("You dont have any profile");
          } else {
            const firstItemJSON = JSON.stringify(filteredData[0]);
            const parsedData = JSON.parse(firstItemJSON);
            setShowLoader(false);
            setProfileContent(parsedData);
          }
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const getWalletEmail = getStorageData("infra:email");
    if (getWalletEmail == null || getWalletEmail == undefined) {
      return history.push("/login");
    } else {
      manageUsersData(getWalletEmail);
    }
  }, []);

  return (
    <div className="profileComponent">
      <div className="profileTop">
        <div className="profileheader"></div>
      </div>

      <div className="profileBottom">
        <div className="profileBody">
          <div className="profileBodyContent">
            <>
              {Object.keys(merchanrContent).length > 0 &&
              merchanrContent.logo_path !== null ? (
                <Image
                  src={`${baseImageURL}${merchanrContent.logo_path}`}
                  alt="business logo"
                  preview={false}
                  className="userProfile"
                />
              ) : (
                <Image src={MonogramIcon} alt="business logo" preview={false} className="userProfile"/>
              )}
              {/* <div className="userProfile"> */}
              {showLoader ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                <>
                  <p className="mainHeading">
                    {profileContent && profileContent.username}
                  </p>
                  <span className="mainHeadingPara">
                    {profileContent && profileContent?.email}
                  </span>
                </>
              )}
            </>
          </div>
        </div>
        <div className="profileFooter">
          <div
            className="footerBtn cursorPointer"
            onClick={() => {
              setShowUserProfile(false);
              history.push("/settings");
            }}
          >
            <SettingIcon />
            <p>Settings</p>
          </div>
          <div
            className="footerBtn cursorPointer"
            onClick={() => {
              toastMessage("Logout successfully");
              localStorage.clear();
              history.push("/login");
            }}
          >
            <LogoutIcon />
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
