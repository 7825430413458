import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginComponent from "../LoginComponent";
import Signupcomponent from "../Signupcomponent";
import { BlockboltLogo } from "svgIcon";

const SelectionComponent = () => {
  const [showSignup, setShowSignup] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getWalletKey = localStorage.getItem("infra:email");
    if (getWalletKey !== null && getWalletKey !== undefined) {
      history.push("/dashboard");
    }
  }, []);

  return (
    <div className="loginFormWrapper">
      <div className="loginFormWrapperLogo">
        <BlockboltLogo />
      </div>
      <ToastContainer />
      <div className="loginForm boxShadow padd30 radius10">
        {showSignup ? <Signupcomponent setShowSignup={setShowSignup}/> : <LoginComponent />}
        {showSignup ? (
          <h4 className="loginOption cursorPointer">
            Have to{" "}
            <span
              className="cursorPointer"
              onClick={() => setShowSignup(false)}
            >
              Login
            </span>
            ?
          </h4>
        ) : (
          <h4 className="loginOption">
            Have to{" "}
            <span className="cursorPointer" onClick={() => setShowSignup(true)}>
              signup
            </span>
            ?
          </h4>
        )}
      </div>
    </div>
  );
};

export default SelectionComponent;
