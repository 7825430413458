import React, { memo } from 'react'
import propTypes from 'prop-types'
import withStyles from 'react-jss'

const styles = {}
const Layout = (props: any) => {
  const { Component, match } = props

  return (
    <section>
      <Component {...props} match={match} />
    </section>
  )
}

Layout.propTypes = {
  Component: propTypes.any.isRequired,
  classes: propTypes.object.isRequired,
  mainView: propTypes.bool,
  leftBar: propTypes.bool,
  emptyBackground: propTypes.bool,
  otherScreen: propTypes.bool,
  match: propTypes.any,
}
export default withStyles(styles)(memo(Layout))