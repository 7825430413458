import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Image } from "antd";
import { getStorageData, toastMessage, useGlobalMerchant } from "utils/helper";
import { CloseIcon } from "svgIcon";
import { Select } from "antd";
import { baseImageURL, baseURL } from "utils/env";
import { getCatogery } from "apiServices/category";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import MonogramIcon from "assets/images/mongram.jpg";

interface ProductFormComponentState {
  name: string;
  price: string;
  description: string;
  sku: string;
  qty: number;
  img: string;
  merchant_email: string;
  merchant_id: string;
}

interface Errors {
  name?: string;
  price?: string;
  description?: string;
  sku?: string;
  qty?: number;
  img?: string;
  merchant_email?: string;
  merchant_id?: string;
}

const ProductFormConponent = ({
  selectedProduct,
  setSelectedProduct,
  setHaveToReloadProductList,
  setShowViewProductList,
}: any) => {
  const getWalletEmail = getStorageData("infra:email");
  const getUserToken = getStorageData("user");
  const { globalMerchantId } = useGlobalMerchant();
  const [categoryList, setCategoryList] = useState([]);
  const [file, setFile] = useState<RcFile | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [categoryOptionsList, setCategoryOptionsList] = useState<any>([]);
  const [onBtnLoader, setOnBtnLoader] = useState(false);

  const [formData, setFormData] = useState<ProductFormComponentState>({
    name: "",
    price: "",
    description: "",
    sku: "",
    qty: 0,
    img: " ",
    merchant_email: getWalletEmail || "",
    merchant_id: globalMerchantId || "",
  });

  const validateForm = () => {
    let formErrors: Errors = {};
    if (!formData.name) formErrors.name = "Please enter name.";
    if (!formData.price) formErrors.price = "Please enter a price.";
    if (!formData.description) formErrors.name = "Please enter description.";
    if (!formData.sku) formErrors.sku = "Please enter a sku.";
    return Object.keys(formErrors).length === 0;
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toastMessage("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getCategoryNamesRecursively = (categories: any[]): any[] => {
    let options: any[] = [];
    categories.forEach((category: any) => {
      if (category.sub_categories && category.sub_categories.length === 0) {
        const option = {
          category_id: category.category_id,
          category_name: category.category_name,
          image_path: category.image_path,
          parent_category_id: category.parent_category_id,
        };
        options.push(option);
      } else if (
        category.sub_categories &&
        category.sub_categories.length > 0
      ) {
        options = options.concat(
          getCategoryNamesRecursively(category.sub_categories)
        );
      }
    });
    return options;
  };

  const manageCatOptionList = async (getData: any) => {
    const categoryOptions = await getCategoryNamesRecursively(
      getData && getData
    );
    setCategoryOptionsList(categoryOptions);
    if (Object.keys(selectedProduct).length > 0) {
      const categoryNames = selectedProduct.categories.map(
        (category: any) => category.name
      );
      setSelectedItems(categoryNames);
    }
  };

  const handleProduct = async () => {
    if (validateForm()) {
      if (globalMerchantId && globalMerchantId !== "") {
        const selectedData =
          categoryOptionsList &&
          categoryOptionsList
            .filter((item: any) => selectedItems.includes(item.category_name))
            .map((item: any) => ({
              id: item.category_id,
              name: item.category_name,
            }));

        if (getWalletEmail !== null && getUserToken !== null && selectedData) {
          setOnBtnLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("name", formData.name);
          formDataImage.append("price", formData.price);
          formDataImage.append("sku", formData.sku);
          formDataImage.append("qty", formData.qty as any);
          formDataImage.append("description", formData.description);
          formDataImage.append("merchant_email", getWalletEmail);
          formDataImage.append("merchant_id", globalMerchantId);
          formDataImage.append("category", JSON.stringify(selectedData));

          if (Object.keys(selectedProduct).length > 0) {
            try {
              const response = await fetch(
                `${baseURL}/update_merchant_product/${selectedProduct.product_id}`,
                {
                  method: "PUT",
                  body: formDataImage,
                  headers: {
                    Authorization: getUserToken,
                  },
                }
              );
              const data = await response.json();
              if (data && data.success === true) {
                setFormData({
                  name: "",
                  price: "",
                  description: "",
                  sku: "",
                  qty: 0,
                  img: " ",
                  merchant_email: getWalletEmail || "",
                  merchant_id: globalMerchantId || "",
                });
                toastMessage("Successfully Updated");
                setOnBtnLoader(false);
                setShowViewProductList(true);
                setHaveToReloadProductList(true);
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          } else {
            try {
              const response = await fetch(`${baseURL}/merchant_product`, {
                method: "POST",
                body: formDataImage,
                headers: {
                  Authorization: getUserToken,
                },
              });
              const data = await response.json();
              if (data && data.success === true) {
                setFormData({
                  name: "",
                  price: "",
                  description: "",
                  sku: "",
                  qty: 0,
                  img: " ",
                  merchant_email: getWalletEmail || "",
                  merchant_id: globalMerchantId || "",
                });
                toastMessage("Successfully Added");
                setOnBtnLoader(false);
                setShowViewProductList(true);
                setHaveToReloadProductList(true);
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        }
      }
    } else {
      toastMessage("something went wrong");
    }
  };

  const onSetFormData = () => {
    setFormData({
      name: selectedProduct.product_name,
      price: selectedProduct.price,
      sku: selectedProduct.sku,
      qty: selectedProduct.qty,
      description: selectedProduct.description,
      img: " ",
      merchant_email: getWalletEmail || "",
      merchant_id: globalMerchantId || "",
    });
  };

  const getCategoryList = () => {
    try {
      getCatogery(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            console.log("OOPS you dont have any record");
          } else {
            setCategoryList(responseData);
            Object.keys(selectedProduct).length > 0 && onSetFormData();
            manageCatOptionList(responseData);
          }
        }
      });
    } catch (error: any) {}
  };

  const handleInput = ({ target: { name, value } }: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    globalMerchantId !== null &&
      globalMerchantId !== undefined &&
      getCategoryList();
  }, []);

  return (
    <div className="formComponent">
      <div className="formComponentHeader">
        <p className="mainHeading">
          {Object.keys(selectedProduct).length > 0
            ? "Edit Product"
            : "Add Product"}
        </p>
        <div
          onClick={() => {
            setShowViewProductList(true);
            setSelectedProduct({});
          }}
          className="cursorPointer"
        >
          <CloseIcon />
        </div>
      </div>
      <Form initialValues={{ remember: true }} layout="vertical">
        <Form.Item
          label="Name"
          rules={[{ required: true, message: "Please enter name!" }]}
        >
          <Input
            name="name"
            placeholder="Filter Coffee"
            value={formData.name}
            onChange={handleInput}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          rules={[{ required: true, message: "Please enter description!" }]}
        >
          <Input.TextArea
            name="description"
            placeholder="Its so delicious"
            value={formData.description}
            onChange={handleInput}
          />
        </Form.Item>
        <Form.Item
          label="Select Category"
          rules={[{ required: true, message: "Please select token!" }]}
        >
          <Select
            mode="multiple"
            placeholder="Inserted are removed"
            value={selectedItems}
            onChange={setSelectedItems}
            style={{ width: "100%" }}
            options={categoryOptionsList.map((category: any) => ({
              value: category.category_name,
              label: category.category_name,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="SKU"
          rules={[{ required: true, message: "Please enter sku!" }]}
        >
          <Input
            name="sku"
            placeholder="KS593528NT"
            value={formData.sku}
            onChange={handleInput}
          />
        </Form.Item>
        <Form.Item
          label="Quantity"
          rules={[{ required: true, message: "Please enter quantity!" }]}
        >
          <Input
            name="qty"
            type="number"
            placeholder="250"
            value={formData.qty}
            onChange={handleInput}
          />
        </Form.Item>
        <Form.Item
          label="Price (USD)"
          rules={[{ required: true, message: "Please enter price!" }]}
        >
          <Input
            name="price"
            type="number"
            placeholder="Enter price in USD"
            value={formData.price}
            onChange={handleInput}
          />
        </Form.Item>
        <div className="commonProfileWrapper">
          <Form.Item
            name="upload"
            label="Upload Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // rules={[{ required: true, message: "Please upload an image!" }]}
          >
            <div style={{ display: "flex" }}>
              {Object.keys(selectedProduct).length > 0 && (
                <div className="commonProfilePic">
                  {selectedProduct.img !== null ? (
                    <Image
                      src={`${baseImageURL}${selectedProduct.img}`}
                      alt="paylink image"
                      preview={false}
                    />
                  ) : (
                    <Image
                      src={MonogramIcon}
                      alt="paylink image"
                      preview={false}
                    />
                  )}
                </div>
              )}
              <Upload
                name="avatar"
                listType="picture"
                beforeUpload={beforeUpload}
                onChange={handleChange}
                onRemove={() => setFile(null)}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </div>
          </Form.Item>
          {/* <div className="commonProfilePic">
            <Image
              src={MonogramIcon}
              alt="business logo"
              preview={false}
            />
          </div> */}
        </div>
        <div className="imageUploadLimit">
          <p>
            Profile image must be JPG, JPEG, or PNG, no larger than 2MB, and 256
            x 256 pixels in size.
          </p>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="commonButton"
            onClick={() => !onBtnLoader && handleProduct()}
          >
            {onBtnLoader
              ? "Loading..."
              : Object.keys(selectedProduct).length > 0
              ? "Update"
              : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProductFormConponent;
