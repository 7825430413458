import { Button, Image, Switch, Form, Input, Upload } from "antd";
import { getSpecificMerchantSetting } from "apiServices/setting";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseImageURL, baseURL } from "utils/env";
import { getStorageData, toastMessage, useGlobalMerchant } from "utils/helper";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import MonogramIcon from "assets/images/mongram.jpg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface CheckoutComponentState {
  merchant_name: string;
  verification_status: string;
  business_name: string;
  business_description: string;
  support_email: string;
  logo_path: string;
  merchant_email: string;
  enable_emails: boolean;
}

const CheckoutComponent = () => {
  const history = useHistory();
  const walletEmail = getStorageData("infra:email");
  const getUserToken = getStorageData("user");
  const { globalMerchantId } = useGlobalMerchant();
  const [file, setFile] = useState<RcFile | null>(null);
  const [merchantId, setMerchantId] = useState("");
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);

  const [formData, setFormData] = useState<CheckoutComponentState>({
    merchant_name: "",
    verification_status: "Pending",
    business_name: "",
    business_description: "",
    support_email: "",
    logo_path: " ",
    merchant_email: walletEmail || "",
    enable_emails: true,
  });

  const validateForm = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!formData.merchant_name) toastMessage("Please enter a merchant name");
    else if (!formData.business_name)
      toastMessage("Please enter a business name");
    else if (!formData.business_description)
      toastMessage("Please enter a business description");
    else if (!formData.support_email)
      toastMessage("Please enter a support email");
    else if (!emailRegex.test(formData.support_email))
      toastMessage("Please enter a correct email address");
    else return true;
  };

  const onUpdateUsersData = async () => {
    if (validateForm()) {
      if (merchantId !== "") {
        // if (!file) {
        //   toastMessage("Please select an image first.");
        //   return;
        // }
        if (walletEmail !== null && getUserToken !== null) {
          setOnBtnLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("merchant_name", formData.merchant_name);
          formDataImage.append(
            "verification_status",
            formData.verification_status
          );
          formDataImage.append("business_name", formData.business_name);
          formDataImage.append("support_email", formData.support_email);
          formDataImage.append(
            "business_description",
            formData.business_description
          );
          formDataImage.append("enable_emails", "1");
          formDataImage.append("merchant_email", walletEmail);
          formDataImage.append("merchant_id", merchantId);

          try {
            const response = await fetch(`${baseURL}merchant_settings`, {
              method: "PUT",
              body: formDataImage,
              headers: {
                Authorization: getUserToken,
              },
            });
            const data = await response.json();
            if (data) {
              setIsUpdateProfile(true);
              setOnBtnLoader(false);
              toastMessage("Updated Successfully");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }
    }
  };

  const onGetUsersData = (getKey: any) => {
    setShowLoader(true);
    setIsUpdateProfile(false);
    try {
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setFormData(responseData);
          setMerchantId(getKey);
          setShowLoader(false);
        }
      });
    } catch (error: any) {}
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toastMessage("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleImageChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onChange = (checked: boolean) => {
    setFormData({ ...formData, enable_emails: checked }); // Fix the property name here
  };

  useEffect(() => {
    if (walletEmail == null) {
      return history.push("/login");
    } else {
      if (globalMerchantId !== null && globalMerchantId !== undefined) {
        onGetUsersData(globalMerchantId);
      }
    }
  }, [globalMerchantId, isUpdateProfile]);

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Form initialValues={{ remember: true }} layout="vertical">
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Merchant Name</p>
              <p className="subInnerHeadingPara">
                Your merchant name displayed to customers on the checkout page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input
                  placeholder="Merchant Name"
                  value={formData.merchant_name}
                  onChange={(e) =>
                    setFormData({ ...formData, merchant_name: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Business Name</p>
              <p className="subInnerHeadingPara">
                Your business name displayed to customers on the checkout page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input
                  placeholder="Business Name"
                  value={formData.business_name}
                  onChange={(e) =>
                    setFormData({ ...formData, business_name: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Business Description</p>
              <p className="subInnerHeadingPara">
                Your business description displayed to customers on the checkout
                page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[
                  { required: true, message: "Please input description!" },
                ]}
              >
                <Input.TextArea
                  placeholder="Business Description"
                  value={formData.business_description}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      business_description: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Support Email</p>
              <p className="subInnerHeadingPara">
                Support email displayed to customers in email invoice.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[{ required: true, message: "Please input email!" }]}
              >
                <Input
                  placeholder="Support Email"
                  value={formData.support_email}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      support_email: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Send & Receive emails</p>
              <p className="subInnerHeadingPara">
                Send & Receive email updates on each successful payment.
              </p>
            </div>
            <Switch defaultChecked onChange={onChange} />
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer m0">
              <p className="subInnerHeading">Business Logo</p>
              <p className="subInnerHeadingPara">
                Your business logo displayed to customers on the checkout page
              </p>
            </div>
            <div className="businessLogo">
              <div className="commonProfileWrapper">
                <div className="commonProfilePic">
                  {formData.logo_path !== null ? (
                    <Image
                      src={`${baseImageURL}${formData.logo_path}`}
                      alt="business logo"
                      preview={false}
                    />
                  ) : (
                    <Image
                      src={MonogramIcon}
                      alt="business logo"
                      preview={false}
                    />
                  )}
                </div>
                <Form.Item
                  name="upload"
                  label="Upload logo"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  // rules={[
                  //   { required: true, message: "Please upload an image!" },
                  // ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture"
                    beforeUpload={beforeUpload}
                    onChange={handleImageChange}
                    onRemove={() => setFile(null)}
                  >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>
              </div>
              <div className="imageUploadLimit">
                <p>
                  Profile image must be JPG, JPEG, or PNG, no larger than 2MB,
                  and 256 x 256 pixels in size.
                </p>
              </div>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <Button
              onClick={() => !onBtnLoader && onUpdateUsersData()}
              className="commonButton"
            >
              {onBtnLoader ? "Loading...." : "Update Settings"}
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default CheckoutComponent;
