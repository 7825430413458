import { getMerchantOrderDetails } from "apiServices/orders";
import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CustomerAddressComponent = ({ customerOrderList }: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const [orderDetailsText, setOrderDetailsText] = useState("");
  const [orderDetailsContent, setOrderDetailsContent] = useState<any>({});

  useEffect(() => {
    if (customerOrderList.orders) {
      setShowLoader(true);
      const filteredEcommerceOrders = customerOrderList.orders.filter(
        (order: any) => order.order_type === "Ecommerce"
      );

      if (filteredEcommerceOrders.length > 0) {
        const reversedIds = filteredEcommerceOrders
          .map((order: any) => order.order_id)
          .reverse();
        if (reversedIds && reversedIds[0]) {
          try {
            getMerchantOrderDetails(reversedIds[0]).then((response) => {
              const responseData = response.data || response;
              if (responseData && responseData[0]) {
                if (responseData[0].address_one !== null) {
                  setShowLoader(false);
                  setOrderDetailsContent(responseData[0]);
                  setOrderDetailsText("");
                } else {
                  setShowLoader(false);
                  setOrderDetailsText("OOPS! you don't have any profile");
                }
              } else {
                setShowLoader(false);
                setOrderDetailsText("OOPS! you don't have any profile");
              }
            });
          } catch (error: any) { }
        } else {
          setShowLoader(false);
          setOrderDetailsText("OOPS! you don't have any profile");
        }
      } else {
        setShowLoader(false);
        setOrderDetailsText("OOPS! you don't have any profile");
      }
    }
  }, [customerOrderList]);

  return (
    <div>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          {orderDetailsText !== "" && <p>{orderDetailsText}</p>}
          {Object.keys(orderDetailsContent).length > 0 && (
            <>
              <div className="addressContainer">
                <div className="paymentDetails">
                  <div className="paymentDetailsHeader">
                    <p className="subInnerHeading">Shipping Address</p>
                  </div>
                  <p className="subInnerHeadingPara">
                    {orderDetailsContent.address_one}{" "}
                    {orderDetailsContent.address_two}
                  </p>
                  <p className="subInnerHeadingPara">
                    {orderDetailsContent.city}
                  </p>
                  <p className="subInnerHeadingPara">
                    {orderDetailsContent.state} {orderDetailsContent.zip_code}{" "}
                    {orderDetailsContent.country}
                  </p>
                </div>
                <div className="paymentDetails">
                  {orderDetailsContent.same_as_billing === "yes" ? (
                    <>
                      <div className="paymentDetailsHeader">
                        <p className="subInnerHeading">Billing Address</p>
                      </div>
                      <p className="subInnerHeadingPara">
                        Same as Shipping address
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="paymentDetailsHeader">
                        <p className="subInnerHeading">Shipping Address</p>
                      </div>
                      <p className="subInnerHeadingPara">
                        {orderDetailsContent.saddress_one}{" "}
                        {orderDetailsContent.saddress_two}
                      </p>
                      <p className="subInnerHeadingPara">
                        {orderDetailsContent.scity}
                      </p>
                      <p className="subInnerHeadingPara">
                        {orderDetailsContent.sstate}{" "}
                        {orderDetailsContent.szip_code}{" "}
                        {orderDetailsContent.scountry}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerAddressComponent;
