import { Button } from "antd";
import React from "react";
import { CautionIcon } from "svgIcon";

const DeleteComponent = ({ setShowDeleteModal, onConfirmation }: any) => {
  return (
    <div className="modelWrapper">
      <div className="cautionIcon">
        <CautionIcon />
      </div>
      <span className="subHeadingPara">Are you sure want to delete this?</span>
      <div className="modelFooterBtns">
        <Button onClick={() => setShowDeleteModal(false)}>No, Keep it.</Button>
        <Button onClick={() => onConfirmation()}>Yes, Delete!</Button>
      </div>
    </div>
  );
};

export default DeleteComponent;
