import React, { useEffect, useState } from "react";
import FormComponent from "../FormComponent";
import ListComponent from "../ListComponent/PaylinksView";
import { checkAddress, toastMessage, useGlobalMerchant } from "utils/helper";
import OpenLinkIcon from "assets/images/open-link.svg";
import CustomerListComponent from "../CustomerListComponent";
import { Image } from "antd";
import { CloseIcon } from "svgIcon";
import PaylinksTable from "../ListComponent/PaylinksTable";

const PaylinksView = (props: any) => {
  const [showFormComponent, setShowFormComponent] = useState(false);
  const [customerOrderList, setCustomerOrderList] = useState<any>({});
  const [onClickCustUrl, setOnClickCustUrl] = useState("");
  const [showOrders, setShowOrders] = useState(true);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showCustOrders, setShowCustOrders] = useState(true);
  const { globalMerchantId } = useGlobalMerchant();

  const listofPaylinks = () => {};

  const onCreatePaylink = () => {
    if (globalMerchantId !== null) {
      setShowFormComponent(!showFormComponent);
    } else {
      toastMessage("Create your business profile first");
    }
  };

  useEffect(() => {
    if (Object.keys(customerOrderList).length > 0) {
      if (customerOrderList.orders && customerOrderList.orders.length > 0) {
        const getUrlLink = checkAddress(
          customerOrderList.orders[0].coin,
          customerOrderList.client_address
        );
        if (getUrlLink !== undefined) {
          setOnClickCustUrl(getUrlLink);
        }
      }
    }
  }, [customerOrderList]);

  return (
    <div className="paylinksWrapper padd30 bgLight radius10">
      <div className="paylinksHeader">
        {showCustomers && Object.keys(customerOrderList).length > 0 ? (
          <>
            <div className="posHeaderContent">
              <p className="mainHeading">
                Customer ID: {customerOrderList.sequence}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{customerOrderList.client_address}</span>
                <a href={onClickCustUrl} target="_blank" rel="noreferrer">
                  <Image
                    src={OpenLinkIcon}
                    alt="image"
                    preview={false}
                    style={{ width: "15px" }}
                  />
                </a>
              </div>
            </div>
            <div className="posHeaderBtn">
              <span
                onClick={() => {
                  setCustomerOrderList({});
                  setShowCustOrders(true);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="paylinksHeaderContent">
              <p className="mainHeading">Paylinks</p>
              <span className="mainHeadingPara">
                Create and manage your paylinks here
              </span>
            </div>
            <div className="paylinksHeaderBtn">
              <button
                onClick={() => onCreatePaylink()}
                className="commonButton"
              >
                Create Paylink
              </button>
            </div>
          </>
        )}
      </div>
      <div className="paylinksContent">
        {showCustomers && Object.keys(customerOrderList).length > 0 ? (
          <div className="paylinksHeader border0">
            <div className="posContentTabs commonTab">
              <button
                className={`commonTabBtn ${
                  showCustOrders ? "active" : "disable"
                }`}
                onClick={() => {
                  setShowCustOrders(true);
                }}
              >
                Orders
              </button>
            </div>
          </div>
        ) : (
          <div className="paylinksHeader border0">
            <div className="posContentTabs commonTab">
              <button
                className={`commonTabBtn ${showOrders ? "active" : "disable"}`}
                onClick={() => {
                  setShowOrders(true);
                  setShowCustomers(false);
                }}
              >
                Orders
              </button>
              <button
                className={`commonTabBtn ${
                  showCustomers ? "active" : "disable"
                }`}
                onClick={() => {
                  setShowCustomers(true);
                  setShowOrders(false);
                }}
              >
                Customers
              </button>
            </div>
          </div>
        )}
        {showOrders && <ListComponent showFormComponent={showFormComponent} />}
        {showCustomers &&
          (Object.keys(customerOrderList).length > 0 ? (
            <>
              {showCustOrders && (
                <PaylinksTable
                  orders={customerOrderList.orders.reverse()}
                  ordersText={"OOPS! you dont have any record"}
                  listofPaylinks={listofPaylinks}
                />
              )}
            </>
          ) : (
            <CustomerListComponent
              setCustomerOrderList={setCustomerOrderList}
            />
          ))}
        {showFormComponent && (
          <FormComponent setShowFormComponent={setShowFormComponent} />
        )}
      </div>
    </div>
  );
};

export default PaylinksView;
