import React, { useEffect, useState } from "react";
import BalanceComponent from "../BalanceComponent";
import ChartComponent from "../ChartComponent";

const HeaderComponent = ({ orderList }: any) => {
  const [manageList, setManageList] = useState({});

  const identifyRecords = () => {
    // const posOrders = orderList.filter((order:any) => order.order_type === "POS");
    // const paylinkOrders = orderList.filter((order:any) => order.order_type === "Paylinks");

    // setManageList({
    //   orderCount: orderList.length,
    //   posOrderCount: posOrders.length,
    //   paylinkOrderCount: paylinkOrders.length,
    // });

    const posOrders = orderList.filter(
      (order: any) => order.order_type === "POS"
    );
    const paylinkOrders = orderList.filter(
      (order: any) => order.order_type === "Paylinks"
    );

    const posOrderCount = posOrders.length;
    const paylinkOrderCount = paylinkOrders.length;

    const totalPosAmount = posOrders.reduce(
      (total: any, order: any) => total + order.amount,
      0
    );
    const totalPaylinkAmount = paylinkOrders.reduce(
      (total: any, order: any) => total + order.amount,
      0
    );
    const totalCombinedAmount = orderList.reduce(
      (total: any, order: any) => total + order.amount,
      0
    );

    setManageList({
      orderCount: orderList.length,
      posOrderCount,
      paylinkOrderCount,
      totalPosAmount,
      totalPaylinkAmount,
      totalCombinedAmount,
    });
  };

  useEffect(() => {
    identifyRecords();
  }, []);

  return (
    <>
      {Object.keys(manageList).length > 0 && (
        <>
          <BalanceComponent manageList={manageList} />
          <ChartComponent manageList={manageList} />
        </>
      )}
    </>
  );
};

export default HeaderComponent;
