import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BlockboltLogo } from "svgIcon";
import { DashboardIcon } from "svgIcon";
import { PosIcon } from "svgIcon";
import { PaylinksIcon } from "svgIcon";
import "./styles.css";
import "../../../assets/global.css";
import ProfileModal from "components/Settings/ProfileModal";
import { getStorageData, useGlobalMerchant } from "utils/helper";
import {
  getMerchantSetting,
  getSpecificMerchantSetting,
} from "apiServices/setting";
import { Image } from "antd";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.jpg";

const HeaderComponent = () => {
  const getWalletEmail = getStorageData("infra:email");
  const [showUserProfile, setShowUserProfile] = useState(false);
  const { setGlobalMerchantId } = useGlobalMerchant();
  const [merchanrContent, setMerchantContent] = useState<any>({});
  let getPathName = window.location.pathname;
  const history = useHistory();

  const onGetMerchantData = (getKey: any) => {
    try {
      getSpecificMerchantSetting(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setMerchantContent(responseData);
        }
      });
    } catch (error: any) {}
  };

  const onGetUsersData = (getWalletEmail: any) => {
    try {
      getMerchantSetting().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          const filteredData = responseData.filter((item: any) => {
            return item.merchant_email === getWalletEmail;
          });
          if (filteredData.length === 0) {
            console.log("You dont have any profile");
          } else {
            const firstItemJSON = JSON.stringify(filteredData[0]);
            const parsedData = JSON.parse(firstItemJSON);
            if (parsedData.merchant_id) {
              setGlobalMerchantId(parsedData.merchant_id);
              onGetMerchantData(parsedData.merchant_id);
            }
          }
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    if (getWalletEmail !== null) {
      onGetUsersData(getWalletEmail);
    }
  }, [getWalletEmail]);

  return (
    <div className="headerWrapper">
      <div className="boltImg">
        <BlockboltLogo />
      </div>
      <div className="headerTabs">
        <div
          className={
            getPathName === "/dashboard" ? "active headerTab" : "headerTab"
          }
          onClick={() => {
            history.push("/dashboard");
          }}
        >
          <DashboardIcon />
          <p>Dashboard</p>
        </div>
        <div
          className={getPathName === "/pos" ? "active headerTab" : "headerTab"}
          onClick={() => {
            history.push("/pos");
          }}
        >
          <PosIcon />
          <p>POS</p>
        </div>
        <div
          className={
            getPathName === "/paylinks" ? "active headerTab" : "headerTab"
          }
          onClick={() => {
            history.push("/paylinks");
          }}
        >
          <PaylinksIcon />
          <p>Paylinks</p>
        </div>
      </div>
      <div
        onClick={() => setShowUserProfile(!showUserProfile)}
        className="userProfile cursorPointer"
      >
        {Object.keys(merchanrContent).length > 0 &&
        merchanrContent.logo_path !== null ? (
          <Image
            src={`${baseImageURL}${merchanrContent.logo_path}`}
            alt="business logo"
            preview={false}
          />
        ) : (
          <Image src={MonogramIcon} alt="business logo" preview={false} />
        )}
      </div>
      {showUserProfile && (
        <ProfileModal
          setShowUserProfile={setShowUserProfile}
          merchanrContent={merchanrContent}
        />
      )}
    </div>
  );
};

export default HeaderComponent;
