import React, { useEffect, useState } from "react";
import PaymentsComponent from "../PaymentsComponent";
import { getPaylinkAPI } from "apiServices/paylinks";
import { useHistory } from "react-router-dom";
import HeaderComponent from "../HeaderComponent";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const DashboardComponent = (props: any) => {
  const history = useHistory();
  const [orderList, setOrderList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);

  const listofPaylinks = (walletEmail: any) => {
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setShowLoader(false);
          const manageOrders = responseData.filter(
            (order: any) => order.status !== "pending"
          );
          const manageWithTypeOrders = manageOrders.filter(
            (order: any) => order.order_type === "Paylinks" || order.order_type === "POS"
          );
          setOrderList(manageWithTypeOrders.reverse());
        } else {
          setShowLoader(false);
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const walletEmail = localStorage.getItem("infra:email");
    if (walletEmail == null || walletEmail == undefined) {
      return history.push("/login");
    } else {
      listofPaylinks(walletEmail);
    }
  }, []);

  return (
    <div className="mainBody">
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : Object.keys(orderList).length > 0 ? (
        <>
          <HeaderComponent orderList={orderList} />
          <PaymentsComponent orderList={orderList} />
        </>
      ) : (
        <h2>Please, set your whole business profile first</h2>
      )}
    </div>
  );
};

export default DashboardComponent;
