import React, { useMemo, useState } from "react";
import { Button, Image, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useHistory } from "react-router-dom";
import { baseImageURL, redirectURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.jpg";
import { DeleteIcon } from "svgIcon";
import { CopyIcon } from "svgIcon";
import {
  ChainCKTestBTC,
  checkAddress,
  checkCoin,
  copyContent,
  selectedChainIcon,
  shortenAddress,
  toastMessage,
} from "utils/helper";
import { deletePaylinkAPI } from "apiServices/paylinks";
import ModalComponent from "components/FormComponent/ModalComponent";
import DeleteComponent from "components/FormComponent/DeleteComponent";

interface DataType {
  key: React.Key;
  payment_id: string;
  merchant_name: string;
  amount: string;
  status: string;
  image_path: string;
}

const PaylinksTable = (props: any) => {
  const { orders, ordersText, listofPaylinks } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [merchantData, setMerchantData] = useState<any>({});
  const [url, setUrl] = useState("");
  const [addressUrl, setAddressUrl] = useState("");

  const encodeContent = (pos: string) => {
    return btoa(pos); // Use btoa to base64 encode the string
  };

  const onRenderAddress = (getOrders: any) => {
    const renderAddress = checkCoin(getOrders.coin, getOrders.digest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  const onRenderSenderAddress = (getOrders: any) => {
    const renderAddress = checkAddress(
      getOrders.coin,
      getOrders.client_address
    );
    if (renderAddress) {
      setAddressUrl(renderAddress);
    }
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemovePaylink(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const onRemovePaylink = (getKey: any) => {
    const newObject = {
      paylink_id: getKey,
    };
    try {
      deletePaylinkAPI(newObject)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData) {
            toastMessage("Deleted Successfully");
            setShowDeleteModal(false);
            listofPaylinks();
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const copyToClipboard = (getKey: any) => {
    const encryptData = encodeContent(`paylink=${getKey}`);
    const callUrl = `${redirectURL}checkout/${encryptData}`;
    if (callUrl) {
      navigator.clipboard.writeText(callUrl);
      toastMessage("Copied!");
    }
  };

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          name: item.name === null ? "-" : item.name,
          image_path: (
            <div className="commonProfilePic">
              {item.image_path !== null ? (
                <Image
                  src={`${baseImageURL}${item.image_path}`}
                  alt="paylink image"
                  preview={false}
                />
              ) : (
                <Image src={MonogramIcon} alt="paylink image" preview={false} />
              )}
            </div>
          ),
          amount: item.amount === null ? "-" : item.amount,
          payment_id: item.status === "completed" ? item.payment_id : "-",
          status: item.status,
          operation:
            item.status !== "completed" ? (
              <>
                <div className="actionIcons">
                  <div onClick={() => copyToClipboard(item.order_id)}>
                    <CopyIcon />
                  </div>
                  <div
                    className="deleteIcon"
                    onClick={() => {
                      setDeleteOrderId(item.order_id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </>
            ) : (
              <Button
                onClick={() => {
                  setShowGenerateModal(true);
                  setMerchantData(item);
                }}
              >
                View
              </Button>
            ),
        };
      })
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Order ID",
      dataIndex: "payment_id",
      key: "payment_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 100,
    },
  ];

  return (
    <>
      <Table dataSource={responseFormate(orders)} columns={columns} />
      {showGenerateModal && (
        <ModalComponent
          title={"Order Information"}
          openable={showGenerateModal}
          closable={() => setShowGenerateModal(false)}
          children={
            <>
              {Object.keys(merchantData).length > 0 &&
                merchantData.status === "completed" && (
                  <>
                    <div className="paymentDetails">
                      <div className="paymentDetailsHeader">
                        <p className="subInnerHeading">Payment Details</p>
                        <Tooltip title={`${merchantData.coin}`}>
                          <span>{selectedChainIcon(merchantData.coin)}</span>
                        </Tooltip>
                      </div>
                      <div className="paymentDetailsBody">
                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">TimeStamp : </p>
                          <p className="subInnerHeading">
                            {merchantData.date_time}
                          </p>
                        </div>
                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">Order ID : </p>
                          <p className="subInnerHeading">
                            {merchantData.payment_id}
                          </p>
                        </div>
                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">Amount : </p>
                          <p className="subInnerHeading">
                            {merchantData.amount} USD
                          </p>
                        </div>
                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">Paid In : </p>
                          <p className="subInnerHeading">
                            {merchantData.coin_amount} {merchantData.coin}
                          </p>
                        </div>
                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">
                            Conversion Rate :{" "}
                          </p>
                          <p className="subInnerHeading">
                            {" "}
                            1 {merchantData.coin} ={" "}
                            {(
                              merchantData.amount / merchantData.coin_amount
                            ).toFixed(4)}
                            * USD
                          </p>
                        </div>

                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">Txn ID :</p>
                          {merchantData.coin === ChainCKTestBTC ? (
                            <p
                              className="subInnerHeading"
                              onClick={() =>
                                toastMessage(
                                  "CKTESTBTC Testnet explorer is not available."
                                )
                              }
                            >
                              <a href={url} target="_blank" rel="noreferrer">
                                {shortenAddress(merchantData.digest)}
                              </a>
                              <span>
                                <CopyIcon />
                              </span>
                            </p>
                          ) : (
                            <p
                              className="subInnerHeading"
                              onClick={() => onRenderAddress(merchantData)}
                            >
                              <a href={url} target="_blank" rel="noreferrer">
                                {shortenAddress(merchantData.digest)}
                              </a>
                              <span onClick={() => copyContent(url)}>
                                <CopyIcon />
                              </span>
                            </p>
                          )}
                        </div>

                        <div className="paymentDetailsBodyContent">
                          <p className="subInnerHeadingPara">
                            Sender Address :
                          </p>
                          <p
                            className="subInnerHeading"
                            onClick={() => onRenderSenderAddress(merchantData)}
                          >
                            {merchantData.client_address == null ||
                            merchantData.client_address == " " ? (
                              "-"
                            ) : (
                              <div style={{ display: "flex" }}>
                                <a
                                  href={addressUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {shortenAddress(merchantData.client_address)}
                                </a>
                                <div onClick={() => copyContent(addressUrl)}>
                                  <CopyIcon />
                                </div>
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </>
          }
        />
      )}

      {showDeleteModal && (
        <ModalComponent
          openable={showDeleteModal}
          closable={() => setShowDeleteModal(false)}
          children={
            <DeleteComponent
              setShowDeleteModal={setShowDeleteModal}
              onConfirmation={onConfirmation}
            />
          }
        />
      )}
    </>
  );
};

export default PaylinksTable;
