import { Button, Switch, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { ChangeEvent, useEffect, useState } from "react";
import { SuiClient, getFullnodeUrl } from "@mysten/sui.js/client";
import { isValidSuiAddress } from "@mysten/sui.js/utils";
import {
  ChainAptos,
  ChainCKTestBTC,
  ChainSUI,
  ChainSolana,
  ChainVenom,
  getStorageData,
  toastMessage,
  useGlobalMerchant,
  validateAddress,
} from "utils/helper";
import { useHistory } from "react-router-dom";
import { DeleteIcon, EditIcon } from "svgIcon";
import {
  addressActiveDeactive,
  createMerchantAddress,
  deleteMerchantAddress,
  getBlockchain,
  getSpecificMerchantAddress,
  updateMerchantAddress,
} from "apiServices/setting";
import { Spin } from "antd";
import { LoadingOutlined, EyeOutlined } from "@ant-design/icons";
import ModalComponent from "components/FormComponent/ModalComponent";
import DeleteComponent from "components/FormComponent/DeleteComponent";

interface InputField {
  id: number;
  blockchain_id: string;
  blockchain_name: string;
  is_active: string;
  wallet_address: string;
}

interface DataType {
  key: React.Key;
  blockchain_name: string;
  wallet_address: string;
}

interface BlockchainItem {
  id: number;
  blockchain_name: string;
  image_link: string | null;
  is_parent: number | null;
  sub_coin: any[]; // Change 'any' to the actual type of sub_coin if you have it defined
}

interface MerchantBlockchainItem {
  id: number;
  merchant_id: number;
  wallet_address: string;
  is_active: number;
  blockchain_name: string;
  image_link: string | null;
  is_parent: number | null;
  blockchain_id: number;
  sub_coin: any[]; // Change 'any' to the actual type of sub_coin if you have it defined
}

export const client = new SuiClient({
  url: getFullnodeUrl(`testnet`),
});

const ConfigurationComponent: React.FC = () => {
  const history = useHistory();
  const walletEmail = getStorageData("infra:email");
  const { globalMerchantId } = useGlobalMerchant();
  const [merchantId, setMerchantId] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [blockChainList, setBlockChainList] = useState<BlockchainItem[]>([]);
  const [merchantBlockChainList, setMerchantBlockChainList] = useState<
    MerchantBlockchainItem[]
  >([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [onUpdateBtnLoader, setOnUpdateBtnLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [isEditId, setIsEditId] = useState("");
  const [isSaveId, setIsSaveId] = useState("");
  const [inputFields, setInputFields] = useState<InputField>({
    id: 0,
    blockchain_id: "",
    blockchain_name: "",
    is_active: "",
    wallet_address: "",
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "blockchain_name",
      key: "blockchain_name",
    },
    {
      title: "Address",
      dataIndex: "wallet_address",
      key: "wallet_address",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      fixed: "right",
      width: 100,
    },
  ];

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    id: any,
    key: any,
    advalue: any,
    name: any
  ) => {
    setInputFields({
      id: id,
      blockchain_id: key,
      blockchain_name: name,
      is_active: advalue,
      wallet_address: event.target.value,
    });
  };

  const onGetMerchantSettings = (
    getMerchantKey: any,
    getBlockchainList: any
  ) => {
    try {
      setIsSuccess(false);
      getSpecificMerchantAddress(getMerchantKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            setMerchantBlockChainList(responseData);
            const newBlockchainsList = getBlockchainList.map(
              (blockchain: any) => {
                const newResponseData = responseData.find(
                  (responseDataItem: any) =>
                    responseDataItem.blockchain_name ===
                    blockchain.blockchain_name
                );

                const walletId = newResponseData ? newResponseData.id : "";

                const walletAddress = newResponseData
                  ? newResponseData.wallet_address
                  : "";

                const getActiveDeactive = newResponseData
                  ? newResponseData.is_active
                  : "";

                return {
                  id: walletId,
                  blockchain_id: blockchain.id,
                  blockchain_name: blockchain.blockchain_name,
                  is_active: getActiveDeactive,
                  wallet_address: walletAddress,
                };
              }
            );
            if (newBlockchainsList && newBlockchainsList.length > 0) {
              setShowLoader(false);
              setData(newBlockchainsList);
            } else {
              setShowLoader(false);
              toastMessage("Dont have any record");
            }
          } else {
            const newBlockchainsList = getBlockchainList.map(
              (blockchain: any) => {
                return {
                  id: 0,
                  blockchain_id: blockchain.id,
                  blockchain_name: blockchain.blockchain_name,
                  is_active: blockchain.is_active,
                  wallet_address: "",
                };
              }
            );
            setData(newBlockchainsList);
            setShowLoader(false);
          }
        })
        .catch((error) => {});
    } catch (error: any) {}
  };

  const resolveNameService = async (name: string) => {
    try {
      const address = await client.resolveNameServiceAddress({ name });
      return address;
    } catch (e) {
      return null;
    }
  };

  const onSwitchToggle = (getItemId: any, getEnDis: any) => {
    const newObject = {
      merchant_blockchain_id: getItemId,
      status: getEnDis === 0 ? 1 : 0,
    };
    try {
      addressActiveDeactive(newObject)
        .then((response) => {
          toastMessage(getEnDis === 0 ? "Activated" : "Deactivated");
          onGetBlockchainsList(globalMerchantId);
        })
        .catch((error) => {});
    } catch (error) {
      toastMessage("something went wrong");
    }
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemoveAddress(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const onGetBlockchainsList = (getMerchantId: any) => {
    setShowLoader(true);
    setIsEditId("");
    setIsSaveId("");
    try {
      getBlockchain()
        .then((response) => {
          const getBlockchainList = response.data || response;
          setBlockChainList(getBlockchainList);
          onGetMerchantSettings(getMerchantId, getBlockchainList);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const manageMerchantChain = (getId: any) => {
    const blockchainItem = merchantBlockChainList.find(
      (item: any) => item.id === getId
    );
    if (blockchainItem) {
      const subCoinIds: any[] = [];
      if (blockchainItem.sub_coin && blockchainItem.sub_coin.length > 0) {
        blockchainItem.sub_coin.map((subCoin: any) =>
          subCoinIds.push(subCoin.id)
        );
        subCoinIds.push(blockchainItem.id);
        return subCoinIds;
      } else {
        subCoinIds.push(blockchainItem.id);
        return subCoinIds;
      }
    } else {
      console.log("Blockchain Item not found");
      return [];
    }
  };

  const managePostChain = (getName: any) => {
    const blockchainItem = blockChainList.find(
      (item: any) => item.blockchain_name === getName
    );
    if (blockchainItem) {
      const subCoinName: any[] = [];
      if (blockchainItem.sub_coin && blockchainItem.sub_coin.length > 0) {
        blockchainItem.sub_coin.map((subCoin: any) =>
          subCoinName.push(subCoin.blockchain_name)
        );
        subCoinName.push(blockchainItem.blockchain_name);
        return subCoinName;
      } else {
        subCoinName.push(blockchainItem.blockchain_name);
        return subCoinName;
      }
    } else {
      console.log("Blockchain Item not found");
      return [];
    }
  };

  const newPaymentFunction = async () => {
    if (isEditId !== "") {
      setOnUpdateBtnLoader(true);
      const data = await manageMerchantChain(inputFields.id);
      const transformedData = [];
      for (let i = 0; i < data.length; i++) {
        transformedData.push({
          id: data[i],
          wallet_address: inputFields.wallet_address,
        });
      }
      try {
        updateMerchantAddress(transformedData)
          .then((response) => {
            toastMessage("updated successfully");
            setOnUpdateBtnLoader(false);
            setIsSuccess(true);
            setInputFields({
              id: 0,
              blockchain_id: "",
              blockchain_name: "",
              is_active: "",
              wallet_address: "",
            });
            onGetBlockchainsList(globalMerchantId);
          })
          .catch((error) => {});
      } catch (error) {
        setOnUpdateBtnLoader(false);
        toastMessage("something went wrong");
      }
    } else {
      setOnBtnLoader(true);
      const transformedData = [];
      const data = await managePostChain(inputFields.blockchain_name);
      for (let i = 0; i < data.length; i++) {
        transformedData.push({
          merchant_id: merchantId,
          blockchain_name: data[i],
          wallet_address: inputFields.wallet_address,
        });
      }
      try {
        createMerchantAddress(transformedData)
          .then((response) => {
            toastMessage("success");
            setOnBtnLoader(false);
            setIsSuccess(true);
            setInputFields({
              id: 0,
              blockchain_id: "",
              blockchain_name: "",
              is_active: "",
              wallet_address: "",
            });
            onGetBlockchainsList(globalMerchantId);
          })
          .catch((error) => {});
      } catch (error) {
        setOnBtnLoader(false);
        toastMessage("something went wrong");
      }
    }
  };

  const onSaveButtonClick = async (getWalletKey: any) => {
    if (inputFields.wallet_address !== "") {
      if (inputFields.blockchain_name === ChainSUI) {
        if (inputFields.wallet_address.includes(".sui")) {
          let getAddress = await resolveNameService(inputFields.wallet_address);
          if (!getAddress) {
            toastMessage("Is not a valid sns name");
            return;
          } else {
            newPaymentFunction();
          }
        } else {
          const isSuiAddress = isValidSuiAddress(inputFields.wallet_address);
          if (isSuiAddress) {
            newPaymentFunction();
          } else {
            toastMessage("Is not a valid sui address");
            return;
          }
          return;
        }
      } else {
        const isValidAddress = validateAddress(inputFields.wallet_address);
        if (
          isValidAddress === ChainAptos ||
          isValidAddress === ChainVenom ||
          isValidAddress === ChainSolana ||
          isValidAddress === ChainCKTestBTC
        ) {
          newPaymentFunction();
        } else {
          toastMessage("Is not a valid address");
          return;
        }
      }
    } else {
      toastMessage("Please fill up the wallet address.");
    }
  };

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          blockchain_name: item.blockchain_name,
          wallet_address:
            item.blockchain_id === isEditId ? (
              <input
                type="text"
                className="inputLayout"
                placeholder="wallet address"
                name="wallet_address"
                value={
                  inputFields.blockchain_id == item.blockchain_id
                    ? inputFields.wallet_address
                    : item.wallet_address
                }
                onChange={(event) =>
                  handleInputChange(
                    event,
                    item.id,
                    item.blockchain_id,
                    item.is_active,
                    item.blockchain_name
                  )
                }
              />
            ) : item.wallet_address === "" ? (
              <input
                type="text"
                className="inputLayout"
                placeholder="wallet address"
                name="wallet_address"
                value={
                  inputFields.blockchain_id == item.blockchain_id
                    ? inputFields.wallet_address
                    : ""
                }
                onChange={(event) =>
                  handleInputChange(
                    event,
                    item.id,
                    item.blockchain_id,
                    item.is_active,
                    item.blockchain_name
                  )
                }
              />
            ) : (
              item.wallet_address
            ),
          operation:
            item.blockchain_id === isEditId ? (
              <Button
                onClick={() =>
                  !onUpdateBtnLoader && onSaveButtonClick(item.blockchain_id)
                }
              >
                {onUpdateBtnLoader ? "Loading..." : "Update"}
              </Button>
            ) : item.wallet_address === "" ? (
              <Button
                onClick={() => {
                  !onBtnLoader && onSaveButtonClick(item.blockchain_id);
                  !onBtnLoader && setIsSaveId(item.blockchain_id);
                }}
              >
                {onBtnLoader
                  ? item.blockchain_id === isSaveId
                    ? "Loading..."
                    : "Save"
                  : "Save"}
              </Button>
            ) : (
              <>
                <div className="actionIcons">
                  <div>
                    <Switch
                      checked={item.is_active === 0 ? false : true}
                      onChange={() => onSwitchToggle(item.id, item.is_active)}
                    />
                  </div>
                  <div
                    onClick={() => setIsEditId(item.blockchain_id)}
                    className="editIcon"
                  >
                    <EditIcon />
                  </div>
                  <div
                    className="deleteIcon"
                    onClick={() => {
                      setDeleteOrderId(item.id);
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </>
            ),
        };
      })
    );
  };

  const onRemoveAddress = async (getKey: any) => {
    const data = await manageMerchantChain(getKey);
    const transformedData = [];
    for (let i = 0; i < data.length; i++) {
      transformedData.push({
        merchant_blockchain_id: data[i],
      });
    }
    try {
      deleteMerchantAddress(transformedData)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData) {
            onGetBlockchainsList(globalMerchantId);
            toastMessage("Deleted Successfully");
            setShowDeleteModal(false);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (walletEmail == null) {
      return history.push("/login");
    } else {
      if (globalMerchantId !== null && globalMerchantId !== undefined) {
        setMerchantId(globalMerchantId);
        onGetBlockchainsList(globalMerchantId);
      }
    }
  }, [isSuccess, globalMerchantId]);

  return (
    <div className="configDataInfo">
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <Table dataSource={responseFormate(data)} columns={columns} />
          {showDeleteModal && (
            <ModalComponent
              openable={showDeleteModal}
              closable={() => setShowDeleteModal(false)}
              children={
                <DeleteComponent
                  setShowDeleteModal={setShowDeleteModal}
                  onConfirmation={onConfirmation}
                />
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default ConfigurationComponent;
