import React from "react";
import { useHistory } from "react-router-dom";
import { DashboardIcon } from "svgIcon";
import { PosIcon } from "svgIcon";
import { PaylinksIcon } from "svgIcon";
import { SettingIcon } from "svgIcon";
import "./style.css";
import "../../../assets/global.css";

const SideComponent = () => {
  let getPathName = window.location.pathname;
  const history = useHistory();

  return (
    <>
      <div className="sideBar bgLight ">
        <div
          className={
            getPathName === "/dashboard" ? "active sideTab" : "sideTab"
          }
          onClick={() => {
            history.push("/dashboard");
          }}
        >
          <DashboardIcon />
        </div>
        <div
          className={getPathName === "/pos" ? "active sideTab" : "sideTab"}
          onClick={() => {
            history.push("/pos");
          }}
        >
          <PosIcon />
        </div>
        <div
          className={getPathName === "/paylinks" ? "active sideTab" : "sideTab"}
          onClick={() => {
            history.push("/paylinks");
          }}
        >
          <PaylinksIcon />
        </div>
        <div
          className={
            getPathName === "/settings"
              ? "active sideTab lastTab"
              : "sideTab lastTab"
          }
          onClick={() => {
            history.push("/settings");
          }}
        >
          <SettingIcon />
        </div>
      </div>
    </>
  );
};
export default SideComponent;
