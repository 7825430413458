import { CreateSingleCategory, DeleteCategory, GetCategory } from "utils/URLs";
import { getWithoutHeader, post } from "utils/v2/api";

export const getCatogery = (data: any) =>
  getWithoutHeader(`${GetCategory}/${data}`);

export const createCategory = (data: any) => {
  return post(`${CreateSingleCategory}`, data);
};

export const deleteCategory = (data: any) => {
  return post(`${DeleteCategory}`, data);
}
