import { useState } from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "react-jss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "./Context";
import CRoutes from "./Routes";
import HeaderComponent from "../components/Layout/HeaderComponent";
import SideComponent from "../components/Layout/SideComponent";
import { GlobalMerchantProvider, getStorageData } from "utils/helper";

export const lightTheme = {
  __bg_whiLig: "rgba(255, 255, 255, 0.75)",
  __bg_gradient: "rgba(0, 0, 0, 0.07)",
};

const Theme = (props: any) => {
  const theme = getStorageData("userTheme");
  const [defaultTheme, setDefaultTheme] = useState(theme);
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <ThemeContext.Provider
          value={{
            setDefaultTheme: setDefaultTheme,
            defaultTheme: defaultTheme,
          }}
        >
          <ToastContainer />
          <Layout>
            <Helmet>
              <title>Blockbolt | Dashboard</title>
              <meta name="description" content="MTC" />
            </Helmet>
            <Layout>
              <GlobalMerchantProvider>
                <HeaderComponent />
                <div className="centerBody">
                  <SideComponent />
                  <CRoutes {...props} />
                </div>
              </GlobalMerchantProvider>
            </Layout>
          </Layout>
        </ThemeContext.Provider>
      </ThemeProvider>
    </>
  );
};
export default Theme;
