import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { AddSmallIcon } from "svgIcon";
import CategoryChildComponent from "../CategoryChildComponent";
import { useGlobalMerchant } from "utils/helper";
import { getCatogery } from "apiServices/category";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type Category = {
  category_name: string;
  category_id: string;
  sub_categories: Category[]; // Add this line
};

type MenuItem = Required<MenuProps>["items"][number];

const CategoryListComponent = ({
  setHaveToReloadCategoryList,
  haveToReloadCategoryList,
}: any) => {
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showChildComponent, setShowChildComponent] = useState(false);
  const { globalMerchantId } = useGlobalMerchant();
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [showLoader, setShowLoader] = useState(false);

  const handleClick = (getContent: any) => {
    if (getContent) {
      setSelectedCategory(getContent);
      setShowChildComponent(true);
    }
  };

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  };

  // const generateMenuItems = (categories: Category[]) => {
  //   const items: MenuItem[] = categories.map((category) => {
  //     const subCategories = category.sub_categories || [];
  //     return getItem(
  //       category.category_name,
  //       category.category_id,
  //       <div onClick={() => handleClick(category)}>
  //         <AddSmallIcon />
  //       </div>,
  //       subCategories.length > 0 ? generateMenuItems(subCategories) : undefined
  //     );
  //   });

  //   return items;
  // };

  const generateMenuItems = (categories: Category[]) => {
    const sortedCategories = categories.sort((a, b) => {
      if (a.sub_categories.length > 0 && b.sub_categories.length === 0) {
        return -1;
      } else if (a.sub_categories.length === 0 && b.sub_categories.length > 0) {
        return 1;
      }
      return 0;
    });

    const items: MenuItem[] = sortedCategories.map((category) => {
      const subCategories = category.sub_categories || [];
      return getItem(
        category.category_name,
        category.category_id,
        <div onClick={() => handleClick(category)}>
          <AddSmallIcon />
        </div>,
        subCategories.length > 0 ? generateMenuItems(subCategories) : undefined
      );
    });

    return items;
  };

  const items: MenuItem[] = generateMenuItems(categoryList && categoryList);

  const rootSubmenuKeys =
    categoryList && categoryList.map((category) => category?.category_id);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const getCategoryList = () => {
    setShowLoader(true);
    // setHaveToReload(false);
    try {
      getCatogery(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setShowLoader(false);
            setCategoryList([]);
          } else {
            setShowLoader(false);
            setCategoryList(responseData);
            setShowChildComponent(true);
            setSelectedCategory(responseData && responseData[0]);
          }
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    if (globalMerchantId !== null && haveToReloadCategoryList) {
      getCategoryList();
      setHaveToReloadCategoryList(false);
    }
  }, [haveToReloadCategoryList]);

  useEffect(() => {
    globalMerchantId !== null && getCategoryList();
  },[]);

  return (
    <div className="posContentCategoryWrapper">
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={items}
          />
          {showChildComponent && (
            <CategoryChildComponent
              setHaveToReloadCategoryList={setHaveToReloadCategoryList}
              setShowChildComponent={setShowChildComponent}
              selectedCategory={selectedCategory}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CategoryListComponent;
