import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { toastMessage } from "utils/helper";
import { baseURL } from "utils/env";

interface SignupcomponentState {
  username: string;
  password: string;
  email: string;
}

const Signupcomponent = ({ setShowSignup }: any) => {
  const history = useHistory();
  const [formData, setFormData] = useState<SignupcomponentState>({
    username: "",
    password: "",
    email: "",
  });

  const handleSignup = async () => {
    if (
      formData.email !== "" &&
      formData.username !== "" &&
      formData.password !== ""
    ) {
      try {
        axios.post(`${baseURL}user/signup`, formData).then((response) => {
          toastMessage("Signup successfully");
          setShowSignup(false);
        });
      } catch (error) {}
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      autoComplete="off"
    >
      <p className="mainHeading">Sign Up</p>
      <Form.Item
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input
          placeholder="Enter username"
          value={formData.username}
          onChange={(e) =>
            setFormData({ ...formData, username: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input
          placeholder="Enter email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Enter password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleSignup}
          className="commonButton"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Signupcomponent;
