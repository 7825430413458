import React from "react";
import { BalanceIcon } from "svgIcon";
import { DocumentIcon } from "svgIcon";

const BalanceComponent = ({ manageList }: any) => {
  function shortenValue(value: any): any {
    return parseFloat(value.toFixed(2));
  }
  return (
    <div className="dashboardHeader">
      <div className="balanceWrapper padd30">
        <div className="balanceWrapperImg">
          <BalanceIcon />
        </div>
        <div className="balanceWrapperContent">
          <div className="ttlEarned">
            <p>${shortenValue(manageList.totalCombinedAmount)}</p>
            <span className="mainHeadingPara">Total Earned</span>
          </div>
          <div className="ttlTransaction">
            <p>{manageList.orderCount}</p>
            <span className="mainHeadingPara">Total Transaction</span>
          </div>
        </div>
      </div>
      <div className="documentWrapper padd30">
        <div className="documentWrapperImg">
          <DocumentIcon />
        </div>
        <div className="documentWrapperContent">
          <p>Documentation</p>
        </div>
      </div>
    </div>
  );
};

export default BalanceComponent;
