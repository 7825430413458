import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getStorageData } from "utils/helper";

const NotFound = () => {
  const history = useHistory();
  useEffect(() => {
    const getWalletEmail = getStorageData("infra:email");
    if (getWalletEmail == null) {
      return history.push("/login");
    } else {
      return history.push("/dashboard");
    }
  }, [])
  return (
    <div className="notFound">
      <p className="mainHeading">Oops! page is not found</p>
      <p className="mainHeadingPara">
        The page you are looking for doesn't exist or an other error occurred.
      </p>
      <button className="commonButton" onClick={() => history.push('/dashboard')}>Go to Dashboard</button>
    </div>
  );
};

export default NotFound;
