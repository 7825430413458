import {
  Button,
  Form,
  Image,
  Input,
  Switch,
  Upload,
  message,
  Spin,
  Select,
} from "antd";
import { getMerchantEcommerce } from "apiServices/setting";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseURL, redirectURL } from "utils/env";
import { getStorageData, toastMessage, useGlobalMerchant } from "utils/helper";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { LoadingOutlined } from "@ant-design/icons";
import { EyeOutlined } from "@ant-design/icons";
import { TiltArrow } from "svgIcon";

interface EcommerceComponentState {
  ecom_title: string;
  ecom_des: string;
  shop_title: string;
  shop_des: string;
  shipping_address: number;
}

const EcommerceComponent = () => {
  const history = useHistory();
  const walletEmail = getStorageData("infra:email");
  const getUserToken = getStorageData("user");
  const { globalMerchantId } = useGlobalMerchant();
  const [file, setFile] = useState<RcFile | null>(null);
  const [merchantId, setMerchantId] = useState("");
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSmallLoader, setShowSmallLoader] = useState(false);
  const [onBtnLoader, setOnBtnLoader] = useState(false);

  const { Option } = Select;
  const [selectedLayout, setSelectedLayout] = useState("Basic Layout");

  const [formData, setFormData] = useState<EcommerceComponentState>({
    ecom_title: "",
    ecom_des: "",
    shop_title: "",
    shop_des: "",
    shipping_address: 1,
  });

  const handleChange = (value: string) => {
    setSelectedLayout(value);
    onUpdateThemeSelection(value);
  };

  const validateForm = () => {
    if (!formData.ecom_title) toastMessage("Please enter a ecommerce name");
    else if (!formData.ecom_des) toastMessage("Please enter a ecommerce desc");
    else if (!formData.shop_title) toastMessage("Please enter a shop name");
    else if (!formData.shop_des) toastMessage("Please enter a shop desc");
    else return true;
  };

  const onUpdateThemeSelection = async (getValue: any) => {
    if (validateForm()) {
      if (merchantId !== "") {
        if (walletEmail !== null && getUserToken !== null) {
          setShowSmallLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("ecom_title", formData.ecom_title);
          formDataImage.append("ecom_des", formData.ecom_des);
          formDataImage.append("ecom_theme", getValue);
          formDataImage.append("shop_title", formData.shop_title);
          formDataImage.append("shop_des", formData.shop_des);
          formDataImage.append(
            "shipping_address",
            formData.shipping_address === 0 ? "0" : "1"
          );
          formDataImage.append("merchant_id", merchantId);
          try {
            const response = await fetch(`${baseURL}ecommerce`, {
              method: "PUT",
              body: formDataImage,
              headers: {
                Authorization: getUserToken,
              },
            });
            const data = await response.json();
            if (data) {
              setIsUpdateProfile(true);
              setShowSmallLoader(false);
              toastMessage("Updated Successfully");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }
    }
  };

  const onUpdateUsersData = async () => {
    if (validateForm()) {
      if (merchantId !== "") {
        // if (!file) {
        //   toastMessage("Please select an image first.");
        //   return;
        // }
        if (walletEmail !== null && getUserToken !== null) {
          setOnBtnLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("ecom_title", formData.ecom_title);
          formDataImage.append("ecom_des", formData.ecom_des);
          formDataImage.append("ecom_theme", selectedLayout);
          formDataImage.append("shop_title", formData.shop_title);
          formDataImage.append("shop_des", formData.shop_des);
          formDataImage.append(
            "shipping_address",
            formData.shipping_address === 0 ? "0" : "1"
          );
          formDataImage.append("merchant_id", merchantId);
          try {
            const response = await fetch(`${baseURL}ecommerce`, {
              method: "PUT",
              body: formDataImage,
              headers: {
                Authorization: getUserToken,
              },
            });
            const data = await response.json();
            if (data) {
              setIsUpdateProfile(true);
              setOnBtnLoader(false);
              toastMessage("Updated Successfully");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }
    }
  };

  const onGetUsersData = (getKey: any) => {
    setShowLoader(true);
    setIsUpdateProfile(false);
    try {
      getMerchantEcommerce(getKey).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          setFormData(responseData);
          setSelectedLayout(responseData.ecom_theme);
          setMerchantId(getKey);
          setShowLoader(false);
        }
      });
    } catch (error: any) {}
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    const maxSize = 2 * 1024 * 1024;
    // const minWidth = 1920;
    // const minHeight = 1080;

    if (!isImage && !isVideo) {
      message.error("You can only upload image or video files!");
      return Promise.reject(new Error("Invalid file type"));
    }

    if (file.size > maxSize) {
      message.error("File size must be no more than 2 MB!");
      return Promise.reject(new Error("File size exceeds limit"));
    }
    setFile(file);
    return false;
  };

  const handleFileChange = (info: any) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const onChange = (checked: Boolean) => {
    setFormData({ ...formData, shipping_address: checked === false ? 0 : 1 }); // Fix the property name here
  };

  useEffect(() => {
    if (walletEmail == null) {
      return history.push("/login");
    } else {
      if (globalMerchantId !== null && globalMerchantId !== undefined) {
        onGetUsersData(globalMerchantId);
      }
    }
  }, [globalMerchantId, isUpdateProfile]);

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Form initialValues={{ remember: true }} layout="vertical">
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Ecommerce Title</p>
              <p className="subInnerHeadingPara">
                Your ecommerce name displayed to customers on the checkout page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input
                  placeholder="Ecommerce Title"
                  value={formData.ecom_title}
                  onChange={(e) =>
                    setFormData({ ...formData, ecom_title: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Ecommerce Description</p>
              <p className="subInnerHeadingPara">
                Your ecommerce description displayed to customers on the
                checkout page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[
                  { required: true, message: "Please input description!" },
                ]}
              >
                <Input.TextArea
                  placeholder="Ecommerce Description"
                  value={formData.ecom_des}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ecom_des: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Shop Title</p>
              <p className="subInnerHeadingPara">
                Your shop name displayed to customers on the checkout page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input
                  placeholder="Shop Title"
                  value={formData.shop_title}
                  onChange={(e) =>
                    setFormData({ ...formData, shop_title: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Shop Description</p>
              <p className="subInnerHeadingPara">
                Your shop description displayed to customers on the checkout
                page.
              </p>
            </div>
            <div className="checkoutContentInput">
              <Form.Item
                rules={[
                  { required: true, message: "Please input description!" },
                ]}
              >
                <Input.TextArea
                  placeholder="Shop Description"
                  value={formData.shop_des}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shop_des: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Shipping & Billing Details</p>
              <p className="subInnerHeadingPara">
                Want to fill up the shipping and billing details on customer
                side.
              </p>
            </div>
            <Switch
              checked={formData.shipping_address === 0 ? false : true}
              onChange={onChange}
            />
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Ecommerce Layout</p>
              <p className="subInnerHeadingPara">
                Your business layout displayed to customers
              </p>
            </div>
            <div>
              {showSmallLoader ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                <div className="eLayout">
                  <Form.Item>
                    <Select
                      defaultValue={selectedLayout}
                      style={{ width: "fit-content", transition: "width 0.3s" }}
                      onChange={handleChange}
                      dropdownMatchSelectWidth={false}
                    >
                      <Option value="Basic Layout">Basic Layout</Option>
                      <Option value="Standard Layout">Standard Layout</Option>
                    </Select>
                  </Form.Item>
                  {merchantId !== "" && (
                    <a
                      href={`${redirectURL}ecommerce/merchant/${btoa(
                        merchantId
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="visitWeb">
                        Visit
                        <TiltArrow />
                      </Button>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <div className="checkoutContentContainer">
              <p className="subInnerHeading">Ecommerce Banner</p>
              <p className="subInnerHeadingPara">
                Your business logo displayed to customers on the checkout page
              </p>
            </div>
            <div className="businessLogo">
              <div className="commonProfileWrapper">
                <Form.Item
                  name="upload"
                  label="Upload video/image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="file"
                    listType="picture"
                    beforeUpload={beforeUpload}
                    onChange={handleFileChange}
                  >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>
              </div>
              <div className="imageUploadLimit">
                <p>Images and videos should not exceed 2 MB in size.</p>
              </div>
            </div>
          </div>
          <div className="checkoutContentWrapper">
            <Button
              onClick={() => !onBtnLoader && onUpdateUsersData()}
              className="commonButton"
            >
              {onBtnLoader ? "Loading...." : "Update Ecommerce Settings"}
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default EcommerceComponent;
