import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { WalletKitProvider } from "@mysten/wallet-kit";
import LoginContainer from "./containers/LoginContainer";
import Theme from "./theme";
import "./App.css";

function App() {
  localStorage.setItem("userTheme", "light");
  return (
    <WalletKitProvider
      features={["sui:signTransactionBlock"]}
      enableUnsafeBurner
    >
      <Router>
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <Route path="/" component={Theme} />
        </Switch>
      </Router>
    </WalletKitProvider>
  );
}

export default App;
