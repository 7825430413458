import React, { useEffect, useState } from "react";
import PaylinksTable from "../PaylinksTable";
import { useHistory } from "react-router-dom";
import { getPaylinkAPI } from "apiServices/paylinks";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ListComponent = ({ showFormComponent }: any) => {
  const [orderList, setOrderList] = useState<any[]>([]);
  const [ordersText, setOrdersText] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const history = useHistory();

  const listofPaylinks = () => {
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setShowLoader(false);
            setOrdersText("OOPS you dont have any record");
          } else {
            const paylinksData = responseData.filter(
              (item: any) => item.order_type === "Paylinks"
            );
            if (paylinksData.length > 0) {
              setOrdersText("");
              setShowLoader(false);
              setOrderList(paylinksData.reverse());
            } else {
              setOrdersText("OOPS you dont have any record");
            }
          }
        } else {
          setShowLoader(false);
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const walletEmail = localStorage.getItem("infra:email");
    if (walletEmail == null || walletEmail == undefined) {
      return history.push("/login");
    } else {
      listofPaylinks();
    }
  }, [showFormComponent]);
  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <PaylinksTable
          orders={orderList}
          ordersText={ordersText}
          listofPaylinks={listofPaylinks}
        />
      )}
    </>
  );
};

export default ListComponent;
