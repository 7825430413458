import { Button, Form, Input } from "antd";
import ModalComponent from "components/FormComponent/ModalComponent";
import type { FormInstance } from "antd/es/form";
import React, { useEffect, useState } from "react";
import { toastMessage, useGlobalMerchant } from "utils/helper";
import { getGenerateKey, postGenerateKey } from "apiServices/setting";
import { CopyIcon, KeyIcon } from "svgIcon";

const GenerateKeyComponent = () => {
  const formRef = React.useRef<FormInstance>(null);
  const { globalMerchantId } = useGlobalMerchant();
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [generateKeyData, setGenerateKeyData] = useState<any>({});

  const copyToClipboard = (getKey: any) => {
    navigator.clipboard.writeText(getKey);
    toastMessage("Copied!");
  };

  const onGenerateKey = () => {
    try {
      postGenerateKey().then((response) => {
        const responseData = response.data || response;
        if (responseData) {
          if (globalMerchantId !== null) {
            setShowGenerateModal(true);
            onCallGenerateKey();
            toastMessage("Congrats!");
          } else {
            toastMessage("Create your business profile first");
          }
        }
      });
    } catch (error: any) { }
  };

  const onCallGenerateKey = () => {
    try {
      getGenerateKey().then((response) => {
        const responseData = response.data || response;
        if (responseData && responseData.status !== false) {
          setGenerateKeyData(responseData);
        }
      });
    } catch (error: any) { }
  };

  useEffect(() => {
    onCallGenerateKey();
  }, []);

  return (
    <div className="generateKeyContentWrapper">
      <p className="subInnerHeadingPara">
        Save the keys safely, as you will not be able to see them again. Your
        old keys will be replaced if you generate new ones.
      </p>
      {Object.keys(generateKeyData).length !== 0 && (
        <div className="generateKeysWrapper">
          <div className="generateKeyType">
            <p className="subInnerHeadingPara">
              Public key
              <KeyIcon />
            </p>
            <span
              className="subInnerHeading"
              onClick={() => copyToClipboard(generateKeyData.public_key)}
            >
              {generateKeyData.public_key}
              <CopyIcon />
            </span>
          </div>
          <div className="generateKeyType">
            <p className="subInnerHeadingPara">
              Private Key
              <KeyIcon />
            </p>
            <span
              className="subInnerHeading"
              onClick={() =>
                copyToClipboard(generateKeyData.private_key_encrypted)
              }
            >
              {generateKeyData.private_key_encrypted}
              <CopyIcon />
            </span>
          </div>
        </div>
      )}

      <Button className="commonButton" onClick={() => onGenerateKey()}>
        Generate Key
      </Button>
      {showGenerateModal && (
        <ModalComponent
          title={"New API Keys"}
          openable={showGenerateModal}
          closable={() => setShowGenerateModal(false)}
          children={
            <>
              <div className="generateKeyModel">
                <p className="subInnerHeadingPara">
                  Your old API key has been deactivated. Be sure to replace
                  accordingly. Visit API docs.
                </p>
                <Form
                  ref={formRef}
                  name="control-ref"
                  style={{ maxWidth: 600 }}
                  layout="vertical"
                >
                  <Form.Item name="Public Key" label="Public Key">
                    <Input placeholder={generateKeyData.public_key} readOnly />
                  </Form.Item>
                  <Form.Item name="Private Key" label="Private Key">
                    <Input
                      placeholder={generateKeyData.private_key_encrypted}
                      readOnly
                    />
                  </Form.Item>
                </Form>
                <p className="subInnerHeadingPara">
                  Save the keys safely, as you will not be able to see them
                  again. Your old keys will be replaced if you generate new
                  ones.
                </p>
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default GenerateKeyComponent;
