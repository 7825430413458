import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { setStorageData, toastMessage } from "utils/helper";
import { login } from "apiServices/app";

interface LoginComponentState {
  password: string;
  email: string;
}

const LoginComponent = () => {
  const [formData, setFormData] = useState<LoginComponentState>({
    password: "",
    email: "",
  });
  const history = useHistory();

  const handleLogin = async () => {
    if (formData.email !== "" && formData.password !== "") {
      login(formData)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.success === false) {
            toastMessage("Invalid email or password.");
          } else {
            toastMessage("Signin successfully");
            setStorageData("infra:email", formData.email);
            setStorageData("user", responseData.loginToken);
            history.push("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      autoComplete="off"
    >
      <Form.Item
        // label="email"
        // name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <p className="mainHeading">Login</p>
        <Input
          placeholder="Enter email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        // label="password"
        // name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Enter password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleLogin}
          className="commonButton"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginComponent;
