import React from "react";
import { Chart } from "react-google-charts";

const ChartComponent = ({ manageList }: any) => {
  const dataOrders = [
    ["Task", "Order per Day"],
    ["POS", manageList.posOrderCount],
    ["Paylinks", manageList.paylinkOrderCount],
  ];

  const optionsOrders = {
    title: "Total Orders",
  };

  const dataAmount = [
    ["Task", "Amount per Day"],
    ["POS", manageList.totalPosAmount],
    ["Paylinks", manageList.totalPaylinkAmount],
  ];

  const optionsAmount = {
    class: "firstChart",
    title: "Total Amount",
  };

  return (
    <div className="chartWrapper">
      <Chart
        chartType="PieChart"
        data={dataOrders}
        options={optionsOrders}
        className="firstChart"
        height="300px"
        width="100%"
      />
      <Chart
        chartType="PieChart"
        data={dataAmount}
        options={optionsAmount}
        className="secondChart"
        height="300px"
        width="100%"
      />
    </div>
  );
};

export default ChartComponent;
