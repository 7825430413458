import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { CloseIcon } from "svgIcon";
import { getStorageData, toastMessage } from "utils/helper";
import { UploadOutlined } from "@ant-design/icons";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import { baseURL } from "utils/env";

interface CategoryParentComponentState {
  name: string;
}

const CategoryParentComponent = ({
  setShowViewCategoryList,
  setHaveToReloadCategoryList,
}: any) => {
  const getUserToken = getStorageData("user");
  const [file, setFile] = useState<File | null>(null);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [formData, setFormData] = useState<CategoryParentComponentState>({
    name: "",
  });

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toastMessage("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onCreateParentCategory = async () => {
    // if (!file) {
    //   toastMessage("Please select an image first.");
    //   return;
    // }
    if (formData.name !== "") {
      if (getUserToken !== null) {
        setOnBtnLoader(true);
        const formDataImage = new FormData();
        file && formDataImage.append("image", file);
        formDataImage.append("category_name", formData.name);
        formDataImage.append("parent_category_id", "");

        try {
          const response = await fetch(`${baseURL}/create_single_categories`, {
            method: "POST",
            body: formDataImage,
            headers: {
              Authorization: getUserToken,
            },
          });
          const data = await response.json();
          if (data) {
            setShowViewCategoryList(true);
            setHaveToReloadCategoryList(true);
            setOnBtnLoader(false);
            toastMessage("Inserted successfully");
          }
        } catch (error: any) {}
      }
    } else {
      toastMessage("Please enter category name");
    }
  };

  return (
    <div className="formComponent">
      <div className="formComponentHeader">
        <p className="mainHeading">Main Category Form</p>
        <div
          onClick={() => setShowViewCategoryList(true)}
          className="cursorPointer"
        >
          <CloseIcon />
        </div>
      </div>
      <Form initialValues={{ remember: true }} layout="vertical">
        <Form.Item
          label="Category Name"
          rules={[{ required: true, message: "Please enter price!" }]}
        >
          <Input
            placeholder="Enter name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          name="upload"
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          // rules={[{ required: true, message: "Please upload an image!" }]}
        >
          <Upload
            name="avatar"
            listType="picture"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            onRemove={() => setFile(null)}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
            <div className="imageUploadLimit">
              <p>
                Profile image must be JPG, JPEG, or PNG, no larger than 2MB, and
                256 x 256 pixels in size.
              </p>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="commonButton"
            onClick={() => !onBtnLoader && onCreateParentCategory()}
          >
            {onBtnLoader ? "Loading..." : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CategoryParentComponent;
