import React, { useEffect, useState } from "react";
import POSView from "../ListComponent/PosView";
import ProductFormConponent from "../Products/ProductFormConponent";
import ProductListComponent from "../Products/ProductListComponent";
import { checkAddress, getStorageData, useGlobalMerchant } from "utils/helper";
import { AddIcon, CloseIcon, RightArrow } from "svgIcon";
import OpenLinkIcon from "assets/images/open-link.svg";
import { getUsers } from "apiServices/app";
import { redirectURL } from "utils/env";
import CategoryListComponent from "../Category/CategoryListComponent";
import CategoryParentComponent from "../Category/CategoryParentComponent";
import { Button, Image, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import CustomerListComponent from "../Customer/CustomerListComponent";
import CustomerAddressComponent from "../Customer/CustomerAddressComponent";
import POSTable from "../ListComponent/PosTable";

const POSComponent = () => {
  const [userId, setUserId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const { globalMerchantId } = useGlobalMerchant();
  const [onClickCustUrl, setOnClickCustUrl] = useState("");
  const [showOrders, setShowOrders] = useState(true);
  const [showCategory, setShowCategory] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [customerOrderList, setCustomerOrderList] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [showViewProductList, setShowViewProductList] = useState(true);
  const [haveToReloadCategoryList, setHaveToReloadCategoryList] =
    useState(true);
  const [haveToReloadProductList, setHaveToReloadProductList] = useState(true);
  const [showViewCategoryList, setShowViewCategoryList] = useState(true);
  const [showCustOrders, setShowCustOrders] = useState(true);
  const [showCustAddress, setShowCustAddress] = useState(false);

  const manageUsersData = (getWalletEmail: any) => {
    try {
      getUsers().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            console.log("You dont have any profile");
            setShowLoader(false);
          } else {
            setShowLoader(false);
            setUserId(responseData.user_id);
          }
        } else {
          setShowLoader(false);
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const getWalletEmail = getStorageData("infra:email");
    getWalletEmail !== null && manageUsersData(getWalletEmail);
  }, []);

  useEffect(() => {
    if (Object.keys(customerOrderList).length > 0) {
      if (customerOrderList.orders && customerOrderList.orders.length > 0) {
        const getUrlLink = checkAddress(
          customerOrderList.orders[0].coin,
          customerOrderList.client_address
        );
        if (getUrlLink !== undefined) {
          setOnClickCustUrl(getUrlLink);
        }
      }
    }
  }, [customerOrderList]);

  return (
    <div className="posWrapper padd30 bgLight radius10">
      <div className="posHeader">
        {showCustomers && Object.keys(customerOrderList).length > 0 ? (
          <>
            <div className="posHeaderContent">
              <p className="mainHeading">
                Customer ID: {customerOrderList.sequence}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{customerOrderList.client_address}</span>
                <a href={onClickCustUrl} target="_blank" rel="noreferrer">
                  <Image
                    src={OpenLinkIcon}
                    alt="image"
                    preview={false}
                    style={{ width: "15px" }}
                  />
                </a>
              </div>
            </div>
            <div className="posHeaderBtn">
              <span
                onClick={() => {
                  setCustomerOrderList({});
                  setShowCustOrders(true);
                  setShowCustAddress(false);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="posHeaderContent">
              <p className="mainHeading">POS</p>
              <span className="mainHeadingPara">
                Your most recent pos orders
              </span>
            </div>
            <div className="posHeaderBtn">
              {globalMerchantId !== null && (
                <a
                  className="subInnerHeading"
                  href={`${redirectURL}ecommerce/merchant/${btoa(
                    globalMerchantId
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Storefront
                  <RightArrow />
                </a>
              )}
            </div>
          </>
        )}
      </div>
      <div className="posContent">
        {showLoader ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <>
            <div>
              {showCustomers && Object.keys(customerOrderList).length > 0 ? (
                <div className="posContentHeader">
                  <div className="posContentTabs commonTab">
                    <button
                      className={`commonTabBtn ${
                        showCustOrders ? "active" : "disable"
                      }`}
                      onClick={() => {
                        setShowCustOrders(true);
                        setShowCustAddress(false);
                      }}
                    >
                      Orders
                    </button>
                    <button
                      className={`commonTabBtn ${
                        showCustAddress ? "active" : "disable"
                      }`}
                      onClick={() => {
                        setShowCustOrders(false);
                        setShowCustAddress(true);
                      }}
                    >
                      Address
                    </button>
                  </div>
                </div>
              ) : (
                <div className="posContentHeader">
                  <div className="posContentTabs commonTab">
                    <button
                      className={`commonTabBtn ${
                        showOrders ? "active" : "disable"
                      }`}
                      onClick={() => {
                        setShowOrders(true);
                        setShowCategory(false);
                        setShowProducts(false);
                        setShowCustomers(false);
                      }}
                    >
                      Orders
                    </button>
                    <button
                      className={`commonTabBtn ${
                        showCategory ? "active" : "disable"
                      }`}
                      onClick={() => {
                        setShowCategory(true);
                        setShowOrders(false);
                        setShowProducts(false);
                        setShowCustomers(false);
                      }}
                    >
                      Category
                    </button>
                    <button
                      className={`commonTabBtn ${
                        showProducts ? "active" : "disable"
                      }`}
                      onClick={() => {
                        setShowProducts(true);
                        setShowOrders(false);
                        setShowCategory(false);
                        setShowCustomers(false);
                      }}
                    >
                      Products
                    </button>
                    <button
                      className={`commonTabBtn ${
                        showCustomers ? "active" : "disable"
                      }`}
                      onClick={() => {
                        setShowCustomers(true);
                        setShowProducts(false);
                        setShowOrders(false);
                        setShowCategory(false);
                      }}
                    >
                      Customers
                    </button>
                  </div>
                  <div className="subButtonWrapper">
                    {showOrders && globalMerchantId !== null && (
                      <>
                        <Button
                          type="primary"
                          icon={<LinkOutlined />}
                          size="middle"
                        >
                          <a
                            href={`${redirectURL}pos/merchant/${btoa(
                              globalMerchantId
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Web POS
                          </a>
                        </Button>
                        <Button
                          type="primary"
                          icon={<DownloadOutlined />}
                          size="middle"
                        >
                          <a
                            href={require("assets/apk/app-debug.apk")}
                            download="Storefront"
                          >
                            Android POS App
                          </a>
                        </Button>
                      </>
                    )}
                    {showProducts && (
                      <span
                        className={
                          showViewProductList
                            ? "disable cursorPointer successBtn"
                            : "active cursorPointer successBtn"
                        }
                        onClick={() => setShowViewProductList(false)}
                      >
                        <AddIcon />
                        Add Product
                      </span>
                    )}
                    {showCategory && (
                      <span
                        className={
                          showViewCategoryList
                            ? "disable cursorPointer successBtn"
                            : "active cursorPointer successBtn"
                        }
                        onClick={() => setShowViewCategoryList(false)}
                      >
                        <AddIcon />
                        Main Category
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="posContentMain">
              <>
                {showOrders && <POSView />}
                {showProducts && (
                  <>
                    <ProductListComponent
                      setSelectedProduct={setSelectedProduct}
                      haveToReloadProductList={haveToReloadProductList}
                      setShowViewProductList={setShowViewProductList}
                      setHaveToReloadProductList={setHaveToReloadProductList}
                    />
                    {!showViewProductList && (
                      <ProductFormConponent
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        setHaveToReloadProductList={setHaveToReloadProductList}
                        setShowViewProductList={setShowViewProductList}
                      />
                    )}
                  </>
                )}
                {showCategory && (
                  <>
                    <CategoryListComponent
                      haveToReloadCategoryList={haveToReloadCategoryList}
                      setHaveToReloadCategoryList={setHaveToReloadCategoryList}
                    />
                    {!showViewCategoryList && (
                      <CategoryParentComponent
                        setHaveToReloadCategoryList={
                          setHaveToReloadCategoryList
                        }
                        setShowViewCategoryList={setShowViewCategoryList}
                      />
                    )}
                  </>
                )}
                {showCustomers &&
                  (Object.keys(customerOrderList).length > 0 ? (
                    <>
                      {showCustOrders && (
                        <POSTable
                          orders={customerOrderList.orders.reverse()}
                          ordersText={"OOPS! you dont have any record"}
                        />
                      )}
                      {showCustAddress && (
                        <CustomerAddressComponent
                          customerOrderList={customerOrderList}
                        />
                      )}
                    </>
                  ) : (
                    <CustomerListComponent
                      setCustomerOrderList={setCustomerOrderList}
                    />
                  ))}
              </>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default POSComponent;
