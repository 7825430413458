import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: React.Key;
  payment_id: string;
  amount: string;
  status: string;
  date_time: string;
}

const PaymentsComponent = ({ orderList }: any) => {
  const columns: ColumnsType<DataType> = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date Time",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Order ID",
      dataIndex: "payment_id",
      key: "payment_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "order_type",
    },
  ];

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          amount: item.amount === null ? "-" : item.amount,
          date_time: item.status === "completed" ? item.date_time : "-",
          payment_id: item.status === "completed" ? item.payment_id : "-",
          status: item.status,
          order_type: item.order_type,
        };
      })
    );
  };

  return (
    <div className="paymentWrapper padd30">
      <div className="paymentHeader">
        <p className="mainHeading">Payments</p>
        <span className="mainHeadingPara">Your most recent payments</span>
      </div>
      <div className="paymentContent">
        <Table dataSource={responseFormate(orderList)} columns={columns} />
      </div>
    </div>
  );
};

export default PaymentsComponent;
