import React, { memo } from 'react'
import propTypes from 'prop-types'
import withStyles from 'react-jss'
import { Layout } from 'antd'
import FooterComponent from "components/Layout/FooterComponent";

const styles = {}
const DefaultLayout = (props: any) => {
  const { Component, match } = props
  const { Content } = Layout
  return (
    <Content className="commonBody">
      <Component {...props} match={match} />
      <FooterComponent />
    </Content>
  )
}

DefaultLayout.propTypes = {
  Component: propTypes.any.isRequired,
  classes: propTypes.object.isRequired,
  mainView: propTypes.bool,
  leftBar: propTypes.bool,
  emptyBackground: propTypes.bool,
  otherScreen: propTypes.bool,
  match: propTypes.any,
}
export default withStyles(styles)(memo(DefaultLayout))
