/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Button, Image, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import ModalComponent from "components/FormComponent/ModalComponent";
import {
  getMerchantOrderDetails,
  getMerchantOrderProduct,
} from "apiServices/orders";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.jpg";
import {
  ChainCKTestBTC,
  checkAddress,
  checkCoin,
  copyContent,
  selectedChainIcon,
  shortenAddress,
  toastMessage,
} from "utils/helper";
import { CopyIcon } from "svgIcon";

interface DataType {
  key: React.Key;
  payment_id: string;
  amount: string;
  status: string;
  order_type: string;
  date_time: string;
}

// interface DataType {
//   key: React.Key;
// }

const POSTable = (props: any) => {
  const { orders, ordersText } = props;
  const [orderContent, setOrderContent] = useState<any>({});
  const [orderDetailsContent, setOrderDetailsContent] = useState<any>({});
  const [productContent, setProductContent] = useState<any>([]);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [url, setUrl] = useState("");
  const [addressUrl, setAddressUrl] = useState("");

  const onViewClick = (getOrder: any) => {
    try {
      getMerchantOrderProduct(getOrder.order_id).then((response) => {
        const responseData = response.data || response;
        setProductContent(responseData);
        setOrderContent(getOrder);
        setShowGenerateModal(true);
      });
    } catch (error: any) {}
    if (
      getOrder.status === "completed" &&
      getOrder.order_type === "Ecommerce"
    ) {
      try {
        getMerchantOrderDetails(getOrder.order_id).then((response) => {
          const responseData = response.data || response;
          if (responseData && responseData[0]) {
            setOrderDetailsContent(responseData[0]);
          }
        });
      } catch (error: any) {}
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date Time",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Order ID",
      dataIndex: "payment_id",
      key: "payment_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "order_type",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      dataIndex: "operation",
      width: 100,
    },
  ];

  const onRenderAddress = (getOrders: any) => {
    const renderAddress = checkCoin(getOrders.coin, getOrders.digest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  const onRenderSenderAddress = (getOrders: any) => {
    const renderAddress = checkAddress(
      getOrders.coin,
      getOrders.client_address
    );
    if (renderAddress) {
      setAddressUrl(renderAddress);
    }
  };

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          amount: item.amount === null ? "-" : item.amount,
          date_time: item.status === "completed" ? item.date_time : "-",
          payment_id: item.status === "completed" ? item.payment_id : "-",
          status: item.status,
          order_type: item.order_type,
          operation: <Button onClick={() => onViewClick(item)}>View</Button>,
        };
      })
    );
  };

  return (
    <>
      <Table dataSource={responseFormate(orders)} columns={columns} />
      {showGenerateModal && (
        <ModalComponent
          title={"Order Information"}
          openable={showGenerateModal}
          closable={() => setShowGenerateModal(false)}
          children={
            <>
              <div className="posOrderInfo">
                <div className="productItemsWrapper">
                  <div className="productItemsHeader">
                    <p className="subInnerHeading">Product Details</p>
                    <p className="subHeadingpara">
                      Status:{" "}
                      <span className="sComplete">{orderContent.status}</span>
                    </p>
                  </div>
                  <div className="productItems">
                    {productContent.map((product: any) => (
                      <div className="productItem">
                        <div className="commonProfilePic">
                          {product.image_path !== null ? (
                            <Image
                              src={`${baseImageURL}${product.image_path}`}
                              alt="product image"
                              preview={false}
                            />
                          ) : (
                            <Image
                              src={MonogramIcon}
                              alt="product image"
                              preview={false}
                            />
                          )}
                        </div>
                        <p className="subInnerHeadingPara">{product.name}</p>
                        <p className="subInnerHeadingPara">{product.qty}</p>
                        <span className="subInnerHeading">
                          {product.price} USD
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                {Object.keys(orderContent).length > 0 &&
                  orderContent.status === "completed" && (
                    <>
                      <div className="paymentDetails">
                        <div className="paymentDetailsHeader">
                          <p className="subInnerHeading">Payment Details</p>
                          <Tooltip title={`${orderContent.coin}`}>
                            <span>{selectedChainIcon(orderContent.coin)}</span>
                          </Tooltip>
                        </div>
                        <div className="paymentDetailsBody">
                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">TimeStamp : </p>
                            <p className="subInnerHeading">
                              {orderContent.date_time}
                            </p>
                          </div>

                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">Order ID : </p>
                            <p className="subInnerHeading">
                              {" "}
                              {orderContent.payment_id}
                            </p>
                          </div>
                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">Amount : </p>
                            <p className="subInnerHeading">
                              {" "}
                              {orderContent.amount} USD
                            </p>
                          </div>
                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">Paid In : </p>
                            <p className="subInnerHeading">
                              {" "}
                              {orderContent.coin_amount} {orderContent.coin}
                            </p>
                          </div>
                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">
                              Conversion Rate :{" "}
                            </p>
                            <p className="subInnerHeading">
                              {" "}
                              1 {orderContent.coin} ={" "}
                              {(
                                orderContent.amount / orderContent.coin_amount
                              ).toFixed(4)}
                              * USD
                            </p>
                          </div>

                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">Txn ID :</p>
                            {orderContent.coin === ChainCKTestBTC ? (
                              <p
                                className="subInnerHeading"
                                onClick={() =>
                                  toastMessage(
                                    "CKTESTBTC Testnet explorer is not available."
                                  )
                                }
                              >
                                <a href={url} target="_blank" rel="noreferrer">
                                  {shortenAddress(orderContent.digest)}
                                </a>
                                <span>
                                  <CopyIcon />
                                </span>
                              </p>
                            ) : (
                              <p
                                className="subInnerHeading"
                                onClick={() => onRenderAddress(orderContent)}
                              >
                                <a href={url} target="_blank" rel="noreferrer">
                                  {shortenAddress(orderContent.digest)}
                                </a>
                                <span onClick={() => copyContent(url)}>
                                  <CopyIcon />
                                </span>
                              </p>
                            )}
                          </div>
                          <div className="paymentDetailsBodyContent">
                            <p className="subInnerHeadingPara">
                              Sender Address :
                            </p>
                            <p
                              className="subInnerHeading"
                              onClick={() =>
                                onRenderSenderAddress(orderContent)
                              }
                            >
                              {orderContent.client_address == null ||
                              orderContent.client_address == " " ? (
                                "-"
                              ) : (
                                <>
                                  <p>
                                    <a
                                      href={addressUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {shortenAddress(
                                        orderContent.client_address
                                      )}
                                    </a>
                                    <span
                                      onClick={() => copyContent(addressUrl)}
                                    >
                                      <CopyIcon />
                                    </span>
                                  </p>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {Object.keys(orderContent).length > 0 &&
                  orderContent.status === "completed" && (
                    <>
                      {Object.keys(orderDetailsContent).length > 0 &&
                        orderDetailsContent.address_one !== null && (
                          <>
                            <div className="paymentDetails">
                              <div className="paymentDetailsHeader">
                                <p className="subInnerHeading">
                                  Shipping Address
                                </p>
                              </div>
                              <p className="subInnerHeadingPara">
                                {orderDetailsContent.address_one}{" "}
                                {orderDetailsContent.address_two}
                              </p>
                              <p className="subInnerHeadingPara">
                                {orderDetailsContent.city}
                              </p>
                              <p className="subInnerHeadingPara">
                                {orderDetailsContent.state}{" "}
                                {orderDetailsContent.zip_code}{" "}
                                {orderDetailsContent.country}
                              </p>
                            </div>
                            <div className="paymentDetails">
                              {orderDetailsContent.same_as_billing === "yes" ? (
                                <>
                                  <div className="paymentDetailsHeader">
                                    <p className="subInnerHeading">
                                      Billing Address
                                    </p>
                                  </div>
                                  <p className="subInnerHeadingPara">
                                    Same as Shipping address
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div className="paymentDetailsHeader">
                                    <p className="subInnerHeading">
                                      Shipping Address
                                    </p>
                                  </div>
                                  <p className="subInnerHeadingPara">
                                    {orderDetailsContent.saddress_one}{" "}
                                    {orderDetailsContent.saddress_two}
                                  </p>
                                  <p className="subInnerHeadingPara">
                                    {orderDetailsContent.scity}
                                  </p>
                                  <p className="subInnerHeadingPara">
                                    {orderDetailsContent.sstate}{" "}
                                    {orderDetailsContent.szip_code}{" "}
                                    {orderDetailsContent.scountry}
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        )}
                    </>
                  )}
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default POSTable;
