import { Modal } from "antd";

const ModalComponent = ({ title, openable, closable, children }: any) => {
  return (
    <Modal
      title={title}
      open={openable}
      onOk={closable}
      onCancel={closable}
      footer=""
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
