import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "svgIcon";
import { getStorageData, toastMessage, useGlobalMerchant } from "utils/helper";
import { Select } from "antd";
import { getSpecificMerchantAddress } from "apiServices/setting";
import { Button, Form, Input, Upload, Image } from "antd";
import { baseURL } from "utils/env";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import MonogramIcon from "assets/images/mongram.jpg";

// Define types for the component state
interface FormComponentState {
  merchant_name: string;
  sender_address: string;
  description: string;
  url: string;
  blockchain: string;
  status: string;
  amount: string;
  coin: string;
  txn_digest: string;
}

interface Errors {
  merchant_name?: string;
  sender_address?: string;
  description?: string;
  url?: string;
  blockchain?: string;
  status?: string;
  amount?: string;
  image?: string;
  coin?: string;
  txn_digest?: string;
}

const FormComponent = ({ setShowFormComponent }: any) => {
  // State for the form data
  const getUserToken = getStorageData("user");
  const { globalMerchantId } = useGlobalMerchant();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [chainListing, setChainListing] = useState<
    { blockchain_id: any; blockchain_name: any }[]
  >([]);
  const [file, setFile] = useState<RcFile | null>(null);
  const blockchainNames = chainListing.map((item) => item.blockchain_name);

  const filteredOptions = blockchainNames.filter(
    (name) => !selectedItems.includes(name)
  );

  const [errors, setErrors] = useState<Errors>({
    merchant_name: "",
    sender_address: "",
    description: "",
    url: "",
    blockchain: "",
    status: "",
    amount: "",
    coin: "",
    txn_digest: "",
  });

  const [formData, setFormData] = useState<FormComponentState>({
    merchant_name: " ",
    sender_address: " ",
    description: " ",
    url: "",
    blockchain: "SUI",
    status: "pending",
    amount: "",
    coin: " ",
    txn_digest: " ",
  });

  const validateForm = () => {
    let formErrors: Errors = {};
    if (!formData.merchant_name)
      formErrors.merchant_name = "Please enter merchant name.";
    if (!formData.description)
      formErrors.description = "Please enter a description.";
    if (!formData.amount) formErrors.amount = "Please enter an amount.";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toastMessage("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const createPaylink = async () => {
    if (validateForm()) {
      const randomKey = Math.floor(100000 + Math.random() * 900000);
      const generateUrl = `${window.location.origin}/pay/id=${randomKey}`;
      const selectedData =
        chainListing &&
        chainListing
          .filter((item) => selectedItems.includes(item.blockchain_name))
          .map((item) => ({
            blockchain_id: item.blockchain_id,
            blockchain_name: item.blockchain_name,
          }));

      if (globalMerchantId && globalMerchantId !== "") {
        // if (!file) {
        //   toastMessage("Please select an image first.");
        //   return;
        // }
        if (getUserToken !== null) {
          setOnBtnLoader(true);
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("amount", formData.amount.toString());
          formDataImage.append("coin", formData.coin);
          formDataImage.append("sender_address", formData.sender_address);
          formDataImage.append("network", "Testnet");
          formDataImage.append("status", formData.status);
          formDataImage.append("txn_digest", formData.txn_digest);
          formDataImage.append("url", generateUrl);
          formDataImage.append("description", formData.description);
          formDataImage.append("merchant_name", formData.merchant_name);
          formDataImage.append("order_type", "0");
          formDataImage.append("blockchain", JSON.stringify(selectedData));
          formDataImage.append("merchant_id", globalMerchantId);

          try {
            const response = await fetch(`${baseURL}create_paylinks`, {
              method: "POST",
              body: formDataImage,
              headers: {
                Authorization: getUserToken,
              },
            });
            const data = await response.json();
            if (data && data.status === true) {
              toastMessage("Created Successully!");
              setShowFormComponent(false);
              setOnBtnLoader(false);
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }
    } else {
      toastMessage("something went wrong");
    }
  };

  const onFetchBlockChainName = () => {
    try {
      getSpecificMerchantAddress(globalMerchantId)
        .then((response) => {
          const responseData = response.data || response;
          if (Array.isArray(responseData) && responseData.length > 0) {
            const activeNetworks = responseData.filter(
              (network: any) => network.is_active === 1
            );
            const transformedData = activeNetworks.map((item: any) => ({
              blockchain_id: item.blockchain_id,
              blockchain_name: item.blockchain_name,
            }));
            setChainListing(transformedData);
          } else {
            console.log("No data found or response is not an array.");
          }
        })
        .catch((error) => {});
    } catch (error: any) {}
  };

  useEffect(() => {
    onFetchBlockChainName();
  }, []);

  return (
    <div className="formComponent">
      <div className="formComponentHeader">
        <p className="mainHeading">Link Payment</p>
        <div
          onClick={() => setShowFormComponent(false)}
          className="cursorPointer"
        >
          <CloseIcon />
        </div>
      </div>
      <Form initialValues={{ remember: true }} layout="vertical">
        <Form.Item
          label="Name"
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <Input
            placeholder="Enter Name"
            value={formData.merchant_name}
            onChange={(e) =>
              setFormData({ ...formData, merchant_name: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="Description"
          rules={[{ required: true, message: "Please input description!" }]}
        >
          <Input.TextArea
            placeholder="Enter Description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label="Select Blockchain"
          rules={[{ required: true, message: "Please select token!" }]}
        >
          <Select
            mode="multiple"
            placeholder="Inserted are removed"
            value={selectedItems}
            onChange={setSelectedItems}
            style={{ width: "100%" }}
            options={filteredOptions.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Amount (USD)"
          rules={[{ required: true, message: "Please input amount!" }]}
        >
          <Input
            type="number"
            placeholder="Enter amount in USD"
            value={formData.amount}
            onChange={(e) =>
              setFormData({ ...formData, amount: e.target.value })
            }
          />
        </Form.Item>
        <div className="commonProfileWrapper">
          <Form.Item
            name="upload"
            label="Upload Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // rules={[{ required: true, message: "Please upload an image!" }]}
          >
            <Upload
              name="avatar"
              listType="picture"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onRemove={() => setFile(null)}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          {/* <div className="commonProfilePic">
            <Image
              src={MonogramIcon}
              alt="business logo"
              preview={false}
            />
          </div> */}
        </div>
        <div className="imageUploadLimit">
          <p>
            Profile image must be JPG, JPEG, or PNG, no larger than 2MB, and 256
            x 256 pixels in size.
          </p>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => !onBtnLoader && createPaylink()}
            className="commonButton"
          >
            {onBtnLoader ? "Loading..." : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
