import React from 'react'
import propTypes from 'prop-types'
import Routes from './Routes'

const DashboardPage = (props: any) => {
  return (
    <Routes {...props} />
  )
}
DashboardPage.propTypes = {
  history: propTypes.object.isRequired,
}
export default DashboardPage