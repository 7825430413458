import React from 'react'
import propTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Layout from './Layout'
import DashboardContainer from 'containers/DashboardContainer'

const Routes = (props: any) => {
  return (
    <Switch>
      <Route
        path='/dashboard'
        render={(props) => (
          <Layout
            {...props}
            Component={DashboardContainer}
            mainView={false}
            emptyBackground
          />
        )}
      />
    </Switch>
  )
}

Routes.propTypes = {
  location: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
}

export default Routes