import React from "react";
const FooterComponent = () => {
  return (
    <div className="footerWrapper">
      <p>
        Powered By |{" "}
        <span className="mainHeadingPara">
          <a href={`https://blockbolt.io/`} target="_blank" rel="noreferrer">
            Blockbolt Decentralized Payment Protocol
          </a>
        </span>
      </p>
    </div>
  );
};

export default FooterComponent;
