import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  AptosIcon,
  CkBtcIcon,
  ICPIcon,
  SolanaIcon,
  SolanaUsdcIcon,
  SuiIcon,
  VenomIcon,
} from "svgIcon";

export const toastMessage = (data: any) =>
  toast(data, {
    position: "top-left",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const getStorageData = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const setStorageData = (key: any, data: any) => {
  // const dataTemp = JSON.stringify(data);
  localStorage.setItem(key, data);
};

export const removeStorageItem = (key: any) => {
  localStorage.removeItem(key);
};

interface GlobalMerchantContextType {
  globalMerchantId: string | null;
  setGlobalMerchantId: (merchantId: string | null) => void;
}

const GlobalMerchantContext = createContext<
  GlobalMerchantContextType | undefined
>(undefined);

export function GlobalMerchantProvider({ children }: { children: ReactNode }) {
  const [globalMerchantId, setGlobalMerchantId] = useState<string | null>(null);

  return (
    <GlobalMerchantContext.Provider
      value={{ globalMerchantId, setGlobalMerchantId }}
    >
      {children}
    </GlobalMerchantContext.Provider>
  );
}

export const ChainSUI = "SUI";
export const ChainAptos = "Aptos";
export const ChainVenom = "Venom";
export const ChainSolana = "Solana";
export const ChainSOL = "SOL";
export const ChainSolanaUSDC = "SOL-USDC";
export const ChainICP = "ICP";
export const ChainCKBTC = "ckBTC";
export const ChainCKTestBTC = "ckTESTBTC";

export const validateAddress = (address: string) => {
  const aptosRegex = /^0x[a-fA-F0-9]{64}$/;
  const venomRegex = /^0:[a-fA-F0-9]{64}$/;
  const solanaRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  const icpRegex = /^[a-z2-7]{5}(-[a-z2-7]{5})*(-[a-z2-7]+)?$/;

  if (aptosRegex.test(address)) {
    return ChainAptos;
  } else if (venomRegex.test(address)) {
    return ChainVenom;
  } else if (solanaRegex.test(address)) {
    return ChainSolana;
  } else if (icpRegex.test(address)) {
    return ChainCKTestBTC;
  } else {
    return false;
  }
};

export const checkCoin = (getCoinName: any, getDigest: any) => {
  switch (getCoinName) {
    case "SUI": {
      return `https://suiexplorer.com/txblock/${getDigest}?network=testnet`;
    }
    case "Aptos": {
      return `https://explorer.aptoslabs.com/txn/${getDigest}?network=testnet`;
    }
    case "Venom": {
      return ` https://testnet.venomscan.com/transactions/${getDigest}`;
    }
    case "SOL": {
      return `https://solscan.io/tx/${getDigest}?cluster=devnet`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/tx/${getDigest}?cluster=devnet`;
    }
    case "ckBTC": {
      return `https://dashboard.internetcomputer.org/bitcoin/transaction/${getDigest}`;
    }
  }
};

export const copyContent = (textToCopy: string) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

export const selectedChainIcon = (getCoinName: any) => {
  switch (getCoinName) {
    case "SUI": {
      return <SuiIcon />;
    }
    case "Aptos": {
      return <AptosIcon />;
    }
    case "Venom": {
      return <VenomIcon />;
    }
    case "Solana": {
      return <SolanaIcon />;
    }
    case "SOL": {
      return <SolanaIcon />;
    }
    case "SOL-USDC": {
      return <SolanaUsdcIcon />;
    }
    case "ICP": {
      return <ICPIcon />;
    }
    case "ckBTC": {
      return <CkBtcIcon />;
    }
    case "ckTESTBTC": {
      return <CkBtcIcon />;
    }
  }
};

export const checkAddress = (getCoinName: any, getAddress: any) => {
  switch (getCoinName) {
    case "SUI": {
      return `https://suiexplorer.com/address/${getAddress}?network=testnet`;
    }
    case "Aptos": {
      return `https://explorer.aptoslabs.com/account/${getAddress}?network=testnet`;
    }
    case "Venom": {
      return `https://testnet.venomscan.com/accounts/${getAddress}`;
    }
    case "SOL": {
      return `https://solscan.io/account/${getAddress}?cluster=devnet`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/account/${getAddress}?cluster=devnet`;
    }
    case "ckBTC": {
      return `https://dashboard.internetcomputer.org/bitcoin/transaction/${getAddress}`;
    }
  }
};

export function useGlobalMerchant() {
  const context = useContext(GlobalMerchantContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}

export function shortenAddress(
  text: string,
  start = 5,
  end = 3,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}
